import React, { FC, ReactNode, createContext, useState, useCallback } from 'react';
import { Alert, useMediaQuery, useTheme, Snackbar, AlertColor } from '@mui/material';
import { LayoutContextProps } from './LayoutContext.types'


const defaultContext: LayoutContextProps = {
    snackbarState: {
        open: false,
        message: '',
        severity: 'info',
        autoHideDuration: 6000,
    },
    isMobile: false,
    isTablet: false,
    genericSnackbar: () => null,
};


export const LayoutContext = createContext(defaultContext);

export const LayoutContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const [snackbarState, setSnackbarState] = useState(defaultContext.snackbarState);

    const genericSnackbar = useCallback((message='', severity: AlertColor='info',autoHideDuration=2000  ) => {
        // @ts-ignore
        setSnackbarState({
            message: message,
            severity: severity,
            autoHideDuration: autoHideDuration,
            open: true,
        });
    }, []);

    const closeSnackbar = useCallback(() => {
        setSnackbarState({
            ...snackbarState,
            open: false,
        })
    }, [setSnackbarState, snackbarState])


    return (
        <LayoutContext.Provider
            value={{
                snackbarState,
                isMobile,
                genericSnackbar,
                isTablet,
            }}
        >
            {children}
            <Snackbar
                open={snackbarState.open}
                autoHideDuration={snackbarState.autoHideDuration}
                onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity={snackbarState?.severity}>
                    {snackbarState.message}
                </Alert>
            </Snackbar>
        </LayoutContext.Provider>
    );
};
