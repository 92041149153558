import React from 'react';
import { Button, Grid, Box, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { routes } from 'config';
import { Feedback } from 'clients/feedback/feedbackClient.types';


interface Props {
    feedback: Feedback;
}
export const InterpretationHistoryItem: React.FC<Props> = ({feedback} ) => {

    const navigate = useNavigate();

    return (
        <Paper>
            <Box p={2}>
                <Grid item display="flex" justifyContent="space-between">
                    <Box>
                        {feedback.feedback.map((analysisType, index) => (
                            <Typography variant='h2' display="inline" key={index}>
                                {analysisType.analysis_type}
                                { feedback.feedback.length > 1 && (feedback.feedback.length !== index + 1) && <Typography variant='h2' component="span"> / </Typography>}
                            </Typography>
                        ))}
                    </Box>
                        <Typography variant="body2">
                            {feedback.created_date}
                        </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">
                        Data Urodzenia Pacjenta: {feedback.date_of_birth.substring(0,7)}
                    </Typography>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="end">
                    <Button onClick={() => navigate(routes.interpretationHistoryDetail(feedback.id))}>
                        Zobacz Interpretację i wyniki badań
                    </Button>
                </Grid>
            </Box>
        </Paper>
    );
}