import React, { Fragment, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { KeyboardArrowDown, Person , Add, Menu as MenuIcon,  History, Logout} from '@mui/icons-material';
import { UserContext } from 'contexts/UserContext/UserContext';
import { common, grey} from '@mui/material/colors';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import {routes} from "../../../config";

export const UserMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { isMobile } = useContext(LayoutContext);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const { user, logout } = useContext(UserContext);
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <React.Fragment>
            <IconButton sx={theme => ({
                padding: '10px 20px',
                borderRadius: 10,
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: isMobile ? 'transparent' : theme.palette.error.main,
                color: isMobile ? 'none' : common.white,
                ':hover': {
                    backgroundColor: isMobile ? 'transparent' : theme.palette.error.main,
                }
            })}
                onClick={handleClick}
            >
                {isMobile ?
                    <MenuIcon/>
                    :
                    <Fragment>
                        <Typography>{user.firstName}</Typography>&nbsp;<Typography>{user.lastName}</Typography>
                        <KeyboardArrowDown />
                    </Fragment>
                }
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        padding: 0,
                        '&.MuiList-root': {
                            padding: 0,
                        },
                        '& .MuiSvgIcon-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                            color: grey['600']
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem component={RouterLink} to={routes.account}>
                    <Person /> Twoje Konto
                </MenuItem>
                <MenuItem component={RouterLink} to={routes.interpretation}>
                    <Add /> Nowa Interpretacja
                </MenuItem>
                <MenuItem component={RouterLink} to={routes.interpretationHistory}>
                    <History/> Historia Interpretacji
                </MenuItem>
                <MenuItem onClick={logout}>
                    <Logout /> Wyloguj się
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}