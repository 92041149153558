import React, {FC, ReactElement, useState} from 'react';
import { Grid, styled, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import {DatePicker} from "@mui/x-date-pickers";

export type FiltersType = {
    sex?: string;
    dateOfBirthAfter: Date | null;
    dateOfBirthBefore: Date | null;
}

const DEFAULT_FILTERS: FiltersType = {
    sex: 'all',
    dateOfBirthAfter: null,
    dateOfBirthBefore: null,
};

type Props = {
    defaultFilters?: FiltersType,
    children(props: {
        filters: FiltersType,
    }): ReactElement;
}

const ContainedToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        fontWeight: 300,
        borderColor: theme.palette.error.main,
        '&.Mui-selected': {
            backgroundColor: theme.palette.error.main,
            color: '#FFF',
            fontWeight: 600,
            borderColor: theme.palette.error.main,

        },
    },
}));

export const InterpretationHistoryFilters: FC<Props> = ({ defaultFilters = DEFAULT_FILTERS, children }) => {

    const initialFilters = {
        ...DEFAULT_FILTERS,
        ...defaultFilters,
    };

    const [sex, setSex] = useState(initialFilters?.sex);
    const [dateOfBirthAfter, setDateOfBirthAfter] = useState(initialFilters?.dateOfBirthAfter);
    const [dateOfBirthBefore, setDateOfBirthBefore] = useState(initialFilters?.dateOfBirthBefore);

    return (
        <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={4}>
                <Typography variant="body2">Płeć: </Typography>
                <ContainedToggleButtonGroup
                    value={sex}
                    exclusive
                    onChange={(e, value) => setSex(value)}
                >
                    <ToggleButton value="all">Wszyscy</ToggleButton>
                    <ToggleButton value="f">Kobieta</ToggleButton>
                    <ToggleButton value="m">Mężczyzna</ToggleButton>
                </ContainedToggleButtonGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Typography variant="body2">Minimalna Data Urodzenia Pacjenta</Typography>
                <DatePicker
                    inputFormat="MM.yyyy"
                    views={['year', 'month']}
                    value={dateOfBirthAfter}
                    onChange={(value, keyboardInputValue) => setDateOfBirthAfter(value)}
                    mask="__.__.____"
                    showDaysOutsideCurrentMonth
                    maxDate={new Date()}
                    openTo="year"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Typography variant="body2">Maksymalna Data Urodzenia Pacjenta</Typography>
                <DatePicker
                    inputFormat="MM.yyyy"
                    views={['year', 'month']}
                    value={dateOfBirthBefore}
                    onChange={(value, keyboardInputValue) => setDateOfBirthBefore(value)}
                    mask="__.__.____"
                    showDaysOutsideCurrentMonth
                    maxDate={new Date()}
                    openTo="year"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                        />
                    )}
                />
            </Grid>

            <Grid item xs={12}>
                {children({
                    filters: {
                        sex: sex === 'all' ? undefined : sex,
                        dateOfBirthAfter: dateOfBirthAfter,
                        dateOfBirthBefore: dateOfBirthBefore,
                    },
                })}
            </Grid>
        </Grid>
    );
};