import {FC, ReactNode, useCallback, useContext, useEffect} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { SignUp } from 'clients/user/userClient.types';
import { LocalizationContext} from 'contexts/LocalizationContext/LocalizationContext';
import { validEmail, validPassword } from 'shared/helpers/validation';

type FormCrud = SignUp;

type Field = keyof FormCrud;

interface Props {
    defaultValues?: Partial<FormCrud>;
    onSubmitRequest: (values: FormCrud ) => void;
    onSubmitButtonText: string;
    disabledFields?: Array<Field>;
    hiddenFields?: Array<Field>;
    children?: ReactNode;
    onError?: boolean;

}

const DEFAULT_VALUES: Partial<FormCrud> = {
    firstName: '',
    lastName: '',
    email: '',
    repeatedEmail: ''
};

export const UserAuthForm: FC<Props> = ({
      defaultValues = {},
      onSubmitRequest,
      onSubmitButtonText,
      hiddenFields,
      disabledFields,
      children,
        onError= false,

}) => {

    const { handleSubmit, control, setError, formState: { isSubmitting, errors }, watch } = useForm<FormCrud>({
        defaultValues: {
            ...DEFAULT_VALUES,
            ...defaultValues,
        },
    });

    useEffect(() => {
        if (onError){
            setError('email', { type: 'pattern', message: 'Niepoprawny email lub hasło' })
            setError('password', { type: 'pattern', message: 'Niepoprawny email lub hasło' })
        }

    }, [onError, setError])

    const password = watch('password');
    const email = watch('email');
    const { formDict } = useContext(LocalizationContext)

    const isFieldVisible = useCallback((field: Field) => {
        if (!hiddenFields) {
            return true;
        }
        return hiddenFields.indexOf(field) === -1;
    }, [hiddenFields]);

    const isFieldEnabled = useCallback((field: Field) => {
        if (!disabledFields) {
            return true;
        }
        return disabledFields.indexOf(field) === -1;
    }, [disabledFields]);

    const onSubmit = useCallback((data: FormCrud) => {
        return onSubmitRequest(data);
    }, [onSubmitRequest]);


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3} maxWidth={500}>
                {isFieldVisible('firstName') && (
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="firstName"
                            control={control}
                            rules={{ required: formDict.requiredField }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    variant="outlined"
                                    label={formDict.firstNameField}
                                    error={!!errors.firstName}
                                    helperText={errors.firstName?.message}
                                    disabled={!isFieldEnabled('firstName')}
                                />
                            )}
                        />
                    </Grid>
                )}
                {isFieldVisible('lastName') && (
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="lastName"
                            control={control}
                            rules={{ required: formDict.requiredField }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    variant="outlined"
                                    label={formDict.lastNameField}
                                    error={!!errors.lastName}
                                    helperText={errors.lastName?.message}
                                    disabled={!isFieldEnabled('lastName')}
                                />
                            )}
                        />
                    </Grid>
                )}
                {isFieldVisible('email') && (
                    <Grid item xs={12}>
                        <Controller
                            name="email"
                            control={control}
                            rules={{
                                required: formDict.requiredField,
                                pattern: {
                                    value: validEmail,
                                    message: formDict.emailRules,
                                },
                                // validate: email => domainsEmailValidation(email) || 'System nie zezwala na rejestrację konta z podanej domeny'
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    variant="outlined"
                                    label={formDict.emailField}
                                    name="email"
                                    error={!!errors.email}
                                    helperText={errors.email?.message}
                                    disabled={!isFieldEnabled('email')}
                                />
                            )}
                        />
                    </Grid>
                )}
                {isFieldVisible('repeatedEmail') && (
                    <Grid item xs={12}>
                        <Controller
                            name="repeatedEmail"
                            control={control}
                            rules={{
                                required: formDict.requiredField,
                                validate: repeatedEmail => repeatedEmail === email || formDict.passwordsDoNotMatch,
                                pattern: {
                                    value: validEmail,
                                    message: formDict.emailRules,
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    variant="outlined"
                                    label={formDict.repeatedEmailField}
                                    name="email"
                                    error={!!errors.repeatedEmail}
                                    helperText={errors.repeatedEmail?.message}
                                    disabled={!isFieldEnabled('repeatedEmail')}
                                />
                            )}
                        />
                    </Grid>
                )}
                {isFieldVisible('oldPassword') && (
                    <Grid item xs={12}>
                        <Controller
                            name="oldPassword"
                            control={control}
                            rules={{
                                required: formDict.requiredField,
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    variant="outlined"
                                    autoComplete="off"
                                    label={formDict.oldPasswordField}
                                    type="password"
                                    error={!!errors.oldPassword}
                                    helperText={errors.oldPassword?.message}
                                    disabled={!isFieldEnabled('oldPassword')}
                                />
                            )}
                        />
                    </Grid>
                )}
                {isFieldVisible('password') && (
                    <Grid item xs={12} sm={isFieldVisible('repeatedPassword') ? 6 : 12}>
                        <Controller
                            name="password"
                            control={control}
                            rules={{
                                required: formDict.requiredField,
                                minLength: {
                                    value: 8,
                                    message: formDict.passwordRules,
                                },
                                pattern: {
                                    value: validPassword,
                                    message: formDict.passwordRules,
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    variant="outlined"
                                    autoComplete="off"
                                    label={isFieldVisible('oldPassword') ? formDict.newPasswordField :formDict.passwordField}
                                    type="password"
                                    error={!!errors.password}
                                    helperText={errors.password?.message}
                                    disabled={!isFieldEnabled('password')}
                                />
                            )}
                        />
                    </Grid>
                )}
                {isFieldVisible('repeatedPassword') && (
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="repeatedPassword"
                            control={control}
                            rules={{
                                required: formDict.requiredField,
                                validate: repeatPassword => repeatPassword === password || formDict.passwordsDoNotMatch,
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    variant="outlined"
                                    autoComplete="off"
                                    label={formDict.repeatedPasswordField}
                                    type="password"
                                    error={!!errors.repeatedPassword}
                                    helperText={errors.repeatedPassword?.message}
                                    disabled={!isFieldEnabled('repeatedPassword')}
                                />
                            )}
                        />
                    </Grid>
                )}
                {children && children}
                <Grid item xs={12}>
                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={isSubmitting}
                    >
                        {onSubmitButtonText}
                    </LoadingButton>
                </Grid>
            </Grid>
        </form>
    );
};
