import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { cacheKeys } from 'config';
import { feedbackClient } from 'clients/feedback/feedbackClient';
import { GetFeedbacksParams } from 'clients/feedback/feedbackClient.types';


export const useFeedbacks = (params: GetFeedbacksParams) => {
    const { data: { data } = {}, status, error } = useQuery(
        [cacheKeys.feedback.getFeedbacks, params],
        () =>feedbackClient.getFeedbacks(params),
    );

    return {
        status,
        error: error as AxiosError,
        count: data?.count,
        feedbacks: data?.results || []
    };
};
