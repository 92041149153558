import { useQuery} from "@tanstack/react-query";
import { agreementsClient} from 'clients/agreement/aggrementClient';
import { cacheKeys} from 'config';


export const useAgreements = () => {

    const {data: { results: agreements } = {}, status } = useQuery(
        [cacheKeys.agreements.getAgreements],
        agreementsClient.getAgreements
    );

    return {
        agreements: agreements || [],
        status,
    };
};
