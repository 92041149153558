import { useQuery} from "@tanstack/react-query";
import { analysisTypeClient } from 'clients/analysisType/analysisType';
import { cacheKeys } from 'config';


export const useAnalysisType = () => {

    const {data = [], status } = useQuery(
        [cacheKeys.analysisType.getAnalysisType],
        analysisTypeClient.getAnalysisType,
    );

    return {
        analysisType: data || [],
        status,
    };
};
