import React, {useCallback, useContext} from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { cacheKeys } from 'config';
import { UserAuthForm } from 'views/LoggedOut/partials/AuthForm';
import { SignUp } from 'clients/user/userClient.types';
import { userClient } from 'clients/user/userClient';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';


export const ChangePassword = () => {

    const queryClient = useQueryClient();
    const { genericSnackbar } = useContext(LayoutContext);

    const onChangePassword = useCallback( async(data: SignUp) => {
        try {
            await userClient.changePassword(data);
            await queryClient.invalidateQueries([cacheKeys.user.getMe]);
        } catch {
            genericSnackbar('Cos poszlo nie tak', 'error');

        }
    }, [genericSnackbar, queryClient])

    return (
        <Grid container sx={{ justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <Paper>
                <Box sx={{ padding: 10 }}>
                    <Typography variant='h2' my={3}>Zmień hasło</Typography>
                    <UserAuthForm
                        onSubmitRequest={onChangePassword}
                        onSubmitButtonText='Zmień hasło'
                        hiddenFields={['firstName', 'lastName', 'email','repeatedEmail']}
                        disabledFields={['email']}
                    />
                </Box>
            </Paper>
        </Grid>
    )

}