import { AnswerApi, CustomParameter, DiagnosisParameter, LabTestType} from './diagnosisClient.types';
import { formatDate } from 'shared/helpers/date';
import { setMarker } from 'shared/helpers/form';


export const mapMiddleLayer = (data: any) => {
    data.answers.filter((answer: AnswerApi) => answer.answer)
}
export const mapDiagnosisData = (data: DiagnosisParameter): any => ({
    id_system: 1111,
    sex: data.sex,
    date_of_birth: formatDate(data.date_of_birth || new Date()),
    lab_tests: mapLabTests(data.parameters, data.test_date)
});

const mapLabTests = (data: CustomParameter[], testData: Date) => {
    // @ts-ignore
    const labTest: LabTestType[] = []
    data.map(parameter => {
        const marker = setMarker(parameter.value_numeric,parameter.low, parameter.high)
        if (labTest.length === 0 && parameter.value_numeric){
            const obj = {
                name: parameter.analysisType === 'Morfologia' ? 'Morfologia' : parameter.examinationName,
                test_date: formatDate(testData),
                package_code: parameter.package_code,
                lab_test_results: [{
                    parameter: parameter.parameter,
                    unit: parameter.unit,
                    value_numeric: parameter.value_numeric,
                    low: parameter.low,
                    high: parameter.high,
                    marker: marker === '-' ? null : marker
                }]
        }
            labTest.push(obj)
            return ''
    }
        if (labTest.length > 0 && parameter.value_numeric) {
            const existsLabTest = labTest.find(test => test.name === parameter.analysisType);
            if (!existsLabTest) {
                const obj = {
                    name: parameter.analysisType === 'Morfologia' ? 'Morfologia' : parameter.examinationName,
                    test_date: formatDate(testData),
                    package_code: parameter.package_code,
                    lab_test_results: [{
                        parameter: parameter.parameter,
                        unit: parameter.unit,
                        value_numeric: parameter.value_numeric,
                        low: parameter.low,
                        high: parameter.high,
                        marker: marker === '-' ? null : marker
                    }]
                }
                labTest.push(obj)
            } else {
                existsLabTest.lab_test_results.push({
                    parameter: parameter.parameter,
                    unit: parameter.unit,
                    value_numeric: parameter.value_numeric,
                    low: parameter.low,
                    high: parameter.high,
                    marker: marker === '-' ? null : marker
                })
            }
        }
        return ''
    });
    return labTest
}
