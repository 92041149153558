import { AnalysisTypeTypes } from 'shared/types/analysisType.types';


export const getAnalysisType = (analysisType: AnalysisTypeTypes[]) => {

    const map = new Map();
    map.set('parameters', [])
    analysisType.length > 0 && analysisType.map(item => {
            return ({
                parameters: [...item.lab_tests.map(parameter => parameter.parameters.map(par => {
                    map.set('parameters', [...map.get('parameters'), {
                        ...par,
                        analysisType: item.name,
                        package_code: item.package_code,
                        examinationName: parameter.name,
                        parameter: par.name,
                        unit: '',
                        value_numeric: undefined,
                        low: undefined,
                        high: undefined,
                        marker: '',
                    }])
                    return ''
                }))]
            })
        }
    )
    return map
};


