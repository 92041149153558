import { routes } from 'config';


export const HeaderData = {
    [routes.login]: 'Logowanie do systemu',
    [routes.signup]: 'Rejestracja do systemu',
    [routes.resetPassword]: 'Resetowanie Hasła',
    [routes.interpretation]: 'Spersonalizowana i szczegółowa interpretacja wyników badań laboratoryjnych',
    [routes.interpretationHistory]: 'Historia interpretacji',
    [routes.account]: 'Profil Użytkownika',
    [routes.information]: 'Informacja zwrotna',
    [routes.privacyPolicy]: 'Polityka Prywatności',
    [routes.rulesAndRegulations]: 'Regulamin',
    [routes.reportMedicalIncidents]: 'Zgłaszanie incydentów medycznych',
}