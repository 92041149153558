import { common, grey } from '@mui/material/colors';
import { createTheme, ThemeOptions, responsiveFontSizes } from '@mui/material/styles';
// @ts-ignore
import bgImage from "shared/img/bgbody.svg"


export const themePalette = {
    palette: {
        primary: {
            light: '#0e2247',
            main: '#0e2247',
        },
        secondary: {
            light: '#01127e',
            main: '#01127e',
        },
        success: {
            main: '#ADFFB5',
        },
        error: {
            main: '#f51d44',
        },
    },
};

export const defaultTheme = responsiveFontSizes(createTheme({}));


export const baseTheme: ThemeOptions = {
    typography: {
        fontFamily: 'Montserrat Medium, Plus Jakarta Sans, sans-serif',
    },
    palette: {
        ...themePalette.palette,
    },
    shape: {
        borderRadius: 4,
    },

    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundSize: 'auto',
                    backgroundAttachment: 'fixed',
                    backgroundRepeat: 'repeat-x',
                    backgroundPosition: 'top right',
                    backgroundImage: `url(${bgImage})`,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h1: {
                    fontWeight: 700,
                    fontSize: '28px',
                },
                h2: {
                    fontWeight: 700,
                    fontSize: '20px',
                },
                h3: {
                    fontWeight: 700,
                    fontSize: '15px',
                },
                body2: {
                    color: grey['400'],
                },
            },

        },
        MuiAlert: {
            styleOverrides: {
                standardError: {
                    backgroundColor: themePalette.palette.error.main,
                    color: 'white'
                },
                filledError: {
                    backgroundColor: themePalette.palette.error.main,
                    color: 'white'
                },
                outlinedError: {
                    color: themePalette.palette.error.main,
                }
            }

        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                fullWidth: true,
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    listStyleType: 'number',
                    margin: 0,
                }
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    display: 'list-item',
                    lineHeight: 1.2,
                    margin: 0,
                    "&:nth-child(1)": {
                        margin: 0,
                    },
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    justifyContent: 'space-between',
                    backgroundColor: common.white,
                    height: '60px'
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    height: 1,
                    width: '100%',
                }
            }
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true,
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    backgroundColor: themePalette.palette.error.main,
                    color: common.white,
                    textTransform: 'none',
                }
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    minHeight: '50px',
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    textAlign: 'center',
                    padding: 0,

                },
                body: {
                    border: `1px solid #d2d8ff`,
                    borderTop: 'none',
                    height: 40,
                },
                head: {
                    color: '#01127e',
                    border: `1px solid #d2d8ff`,
                    borderBottom: 'none',
                    fontWeight: 700,
                    fontSize: 12,
                    height: 40,
                    width: '15%',
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: '#f5f9ff',
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {

                root: {
                    borderRadius: 8,
                }
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    fontFamily: 'Montserrat Medium',
                }
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    color: '#01127e',
                    fontWeight: 300,
                    fontSize: 15,
                    fontFamily: 'Montserrat Regular'
                }
            }
        },
    },
};

export const theme = () => createTheme(baseTheme);


export const muiTheme = theme;