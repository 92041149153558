import React, { useCallback, useContext } from 'react';
import { Box, Grid, Paper, Typography} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { cacheKeys } from 'config';
import { UserAuthForm } from 'views/LoggedOut/partials/AuthForm';
import { UserContext } from 'contexts/UserContext/UserContext';
import { SignUp } from 'clients/user/userClient.types';
import { userClient } from 'clients/user/userClient';


export const Account: React.FC = () => {

    const { user, userId, userEmail } = useContext(UserContext);
    const queryClient = useQueryClient();

    const onChangePassword = useCallback( async(data: SignUp) => {
        await userClient.changePassword(data);
    }, [])

    const onUpdateUser = useCallback( async(data: any) => {
        await userClient.updateUserProfile(data, userId);
        await queryClient.invalidateQueries([cacheKeys.user.getMe]);
    }, [queryClient, userId])

    return (
        <Grid container sx={{ justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
            <Paper>
                <Box sx={{ padding: 10 }}>
                    <Typography variant='h2' my={3}>Zmień dane Użytkownika</Typography>
                    <UserAuthForm
                        onSubmitRequest={onUpdateUser}
                        onSubmitButtonText='Zmień dane'
                        hiddenFields={['repeatedEmail', 'password', 'repeatedPassword', 'oldPassword']}
                        disabledFields={['email']}
                        defaultValues={{...user, email: userEmail }}
                    />
                    <Typography variant='h2' my={3}>Zmień hasło</Typography>
                    <UserAuthForm
                        onSubmitRequest={onChangePassword}
                        onSubmitButtonText='Zmień hasło'
                        hiddenFields={['firstName', 'lastName', 'email','repeatedEmail']}
                        disabledFields={['email']}
                    />
                </Box>
            </Paper>
        </Grid>
    )
}
