import {
    ChangePassword,
    ChangePasswordApi,
    SignUp,
    SignUpUserApi,
    UpdateUser, UpdateUserApi,
    User,
    UserApi
} from './userClient.types';


export const mapUserData = (data: UserApi): User => ({
    email: data.email,
    url: data.url,
    userProfile: {
        address: data.user_profile.address,
        fieldOfStudy: data.user_profile.field_of_study,
        firstName: data.user_profile.first_name,
        gradeOfStudy: data.user_profile.grade_of_study,
        lastName: data.user_profile.last_name,
        modeOfStudy: data.user_profile.mode_of_study,
        yearOfStudy: data.user_profile.year_of_study,
    },
    limits: data.limits,
    forceChangePassword: data.force_change_password
});

export const mapSignUpData = (data: SignUp): SignUpUserApi => ({
    email: data.email,
    password: data.password,
    user_profile: {
        address: '',
        field_of_study: '',
        first_name: data.firstName,
        grade_of_study: '',
        last_name: data.lastName,
        mode_of_study: '',
        year_of_study: 0,
    }
});

export const mapChangePasswordData = (data: ChangePassword): ChangePasswordApi => ({
    old_password: data.oldPassword,
    new_password1: data.password,
    new_password2: data.repeatedPassword
});

export const mapUpdateUserProfile = (data: UpdateUser): UpdateUserApi => ({
    first_name: data.firstName,
    last_name: data.lastName,
})

