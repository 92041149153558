import React, { useContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
    Box,
    Divider,
    Grid,
    Paper,
    Link,
    Typography, Alert,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { routes } from 'config';
import { SignUp as SignUpType } from 'clients/user/userClient.types';
import { userClient } from 'clients/user/userClient';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { useAgreements } from 'shared/hooks/agreement/useAgreements';
import ReCaptchaComponent from 'shared/components/reCaptcha/ReCaptchaComponent';
import { UserAuthForm } from './partials/AuthForm';

type AgreementsForm = {
    agreements: string[]
}

export const SignUp = () => {

    const [ registrationError, setRegistrationError ] = useState('')
    const [ showError, setShowError ] = useState(false)
    const { agreements: allAgreements } = useAgreements();
    const { genericSnackbar } = useContext(LayoutContext);
    const { isMobile } = useContext(LayoutContext);

    const navigate = useNavigate();

    const { register, setError, formState: { errors }, watch } = useForm<AgreementsForm>({
        defaultValues: {
            agreements: []
        }
    });

    const watchAgreements = Array(watch('agreements')).flat();

    const requiredIds = useMemo(() => {
        const ids: number[] = []
        if (allAgreements.length > 0) {
            allAgreements.filter(agreement => agreement.required).map(item => ids.push(item.id))
        }
        return ids
    }, [allAgreements]);


    const onSubmit = async (data: SignUpType) => {
        if (requiredIds.length > 0) {
            if (watchAgreements.length === 0) {
                setError('agreements',  { type: 'custom' }, { shouldFocus: true })
                return ''
            }
        }
        const checkedAgreements = watchAgreements.map(Number);
        const allRequired = requiredIds.every(r => checkedAgreements.includes(r))
        if (!allRequired) {
            setError('agreements',  { type: 'custom' }, { shouldFocus: true })
            return ''
        }
        try {
            await userClient.signup(data, checkedAgreements)
            await navigate(routes.information, {
                state: {
                    title: 'Na podany adres email został wysłany link aktywujący'
                },
            })
        } catch (err) {
            if (err instanceof AxiosError) {
                genericSnackbar(err.response?.data.error[0], 'error');
                setRegistrationError(err.response?.data.error[0]);
                await setShowError(true)
                const Element = document.getElementsByTagName('img');
                Element?.[0]?.scrollIntoView({behavior: 'smooth', block: 'center'});


                setTimeout(function () {
                    setShowError(false)
                }, 10000);

            } else {
                genericSnackbar('Coś poszło nie tak', 'error');
            }

        }
    }


    return (
        <Grid container sx={{ justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
            <ReCaptchaComponent/>
            <Paper>
                <Box p={isMobile ? 2 : 10}>
                    <Grid item mb={3} xs={12} textAlign="center">
                        <img src='/assets/logo.svg' height="40px" width="100%" alt='logo'/>
                    </Grid>
                    {showError && <Grid item xs={12} my={2} id='error'>
                        <Alert variant="outlined" severity="error">
                            {registrationError}
                        </Alert>
                    </Grid>}

                    <UserAuthForm
                        onSubmitRequest={onSubmit}
                        onSubmitButtonText="Zarejestruj się"
                        hiddenFields={['oldPassword']}

                    >
                        <Grid item xs={12}>
                            {allAgreements.length > 0 && allAgreements.map(agreement => (
                                <Grid item xs={12}
                                      display="flex"
                                      flexDirection='row'
                                      justifyContent="start"
                                      alignItems="start"
                                >

                                    <input
                                        {...register("agreements", {
                                            // value: agreement.id,
                                            valueAsNumber: true,
                                            setValueAs: v => parseInt(v),
                                        })}
                                        name="agreements"
                                        type="checkbox"
                                        value={agreement.id}
                                        style={{ marginTop: 20 }}
                                    />
                                    <Typography
                                        variant="body2"
                                        color={(errors.agreements && agreement.required && !watchAgreements.includes(agreement.id.toString())) ? "red" : grey['400']}>
                                        <div style={{ display: 'inline', padding: 0 }} dangerouslySetInnerHTML={{__html: agreement.agreementText}} />
                                    </Typography>

                                </Grid>

                            ))}

                        </Grid>
                    </UserAuthForm>
                    <Grid item xs={12}>
                        <Divider style={{ marginTop: 24, marginBottom: 24 }}/>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Typography variant="body2">Nie masz jeszcze konta?</Typography>
                        <Link sx={{ textDecoration: 'none' }} component={RouterLink} to={routes.login}>Zaloguj się</Link>
                    </Grid>
                </Box>
            </Paper>
        </Grid>
    )
}