import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { calculateYears, genderMapper } from 'shared/helpers/results';


type Props = {
    sex?: string;
    date_of_birth: string;
}
export const PatientSummary: React.FC<Props> = ({ sex='unknown', date_of_birth}) => {


    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow sx={{ height: 40 }}>
                        <TableCell>Płeć</TableCell>
                        <TableCell>Wiek</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow sx={{ height: 40 }}>
                        <TableCell>{genderMapper[sex as keyof typeof genderMapper]}</TableCell>
                        <TableCell>{calculateYears(date_of_birth)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}