import React, {Fragment, useContext} from 'react';
import {Container, Divider, Grid, IconButton, Link, Paper, Typography} from '@mui/material';
import {common} from '@mui/material/colors';
import {muiTheme} from 'shared/styles/muiTheme';
import {LayoutContext} from 'contexts/LayoutContext/LayoutContext';
import {AlternateEmail, Circle, Place} from '@mui/icons-material';
import {routes, tryBloodlab} from 'config';

export const Footer = () => {

    const {isMobile} = useContext(LayoutContext);

    const theme = muiTheme();
    const isTryBloodlab = window.location.host === tryBloodlab

    return (
        <Paper component="footer" style={{
            marginTop: 16,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.error.main,
        }}>
            <Container>
                <Grid container justifyContent="space-between" textAlign={isMobile ? 'center' : 'start'}>
                    <Grid item xs={12} sm={8} p={5}>
                        <Typography variant="h1">Kontakt</Typography>
                        <Typography variant="h2" mt={3}>Chcesz wiedzieć więcej?</Typography>
                        <Typography variant="h2" component="span" color={common.white}>Napisz do nas</Typography>
                        <Grid container my={3}>
                            <Grid item display="flex" xs={12} sm={6} justifyContent={isMobile ? 'center' : 'start'}>
                                <AlternateEmail />
                                <Link component="a" href="mailto:info@bloodlab.pl" color={common.white} ml={1}>
                                    info@bloodlab.pl
                                </Link>
                            </Grid>
                            <Grid item display="flex" xs={12} sm={6} justifyContent={isMobile ? 'center' : 'start'} mt={isMobile ? 2 : 0 }>
                                <Place />
                                <Typography color={common.white}>ul. Władysława Łokietka 5 <br/> 87-100 Toruń</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} my={2} alignItems="center" >
                            <Link component="a" href={routes.privacyPolicy} target="_blank" color={common.white}>
                                Polityka Prywatności
                            </Link>
                            <Circle sx={{ height: 10, verticalAlign: 'center' }}/>
                            <Link component="a" href={routes.rulesAndRegulations} target="_blank" color={common.white}>
                                Regulamin
                            </Link>
                            {isTryBloodlab &&
                                <Fragment>
                                    <Circle sx={{ height: 10, verticalAlign: 'center' }}/>
                                    <Link component="a" href={routes.reportMedicalIncidents} target="_blank"
                                       color={common.white}>
                                        Incydenty medyczne
                                    </Link>
                                    <Circle sx={{height: 10, verticalAlign: 'center'}}/>
                                    <Link component="a" href='https://bloodlab.pl/static/pdf/deklaracja_zgodnosci_ue.pdf' target="_blank" color={common.white}>
                                        Deklaracja zgodności
                                    </Link>
                                </Fragment>
                            }

                        </Grid>
                        <IconButton
                            href="https://www.facebook.com/BloodLab-107175078471165"
                            target="_blank"
                            sx={{ padding: 0 }}
                        >
                            <img src='/assets/facebook.svg' alt='facebook'/>
                        </IconButton>
                        <IconButton
                            href="https://www.instagram.com/bloodlab.pl"
                            target="_blank"
                        >
                            <img src='/assets/instagram.svg' alt='facebook'/>
                        </IconButton>
                    </Grid>
                    {! isMobile && (
                        <Grid container xs={12} sm={4} p={2} alignItems="center" justifyContent="center">
                            <img src='/assets/footer.webp' alt='logo' style={{width: "100%", height: "auto"}}/>
                        </Grid>
                    )}
                    <Grid item xs={12} p={5}>
                        <Divider style={{ borderColor: '#4F5151', borderWidth: 1 }}/>
                    </Grid>
                    <Grid item xs={12} px={5}>
                        <img src='/assets/footerLogo.svg' alt='logo' />
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    )
}