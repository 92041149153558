import React, { Fragment, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Typography, Paper } from '@mui/material';
import { getAnalysisType } from 'shared/helpers/data';
import { DiagnosisParameter, OCRParameter } from 'clients/diagnosis/diagnosisClient.types';
import { fillOCRValues } from 'shared/helpers/form';
import { Stepper } from 'views/LoggedIn/Interpretation/partials/Stepper';
import { useAnalysisType } from 'shared/hooks/analysisType/useAnalysisType';
import { ExaminationTable } from "./ExaminationTable";
import { PatientSummary } from 'views/LoggedIn/Results/partials/PatientSummary';



interface Props {
    onSubmit: (values: any) => void;
    chosenAnalysis: any;
    OCRResults?: OCRParameter[];
    defaultParameters?: OCRParameter[];
    sex: string;
    birthDate: string

}

export const PatientInformation: React.FC<Props> = ({onSubmit, chosenAnalysis, OCRResults = [], defaultParameters, sex, birthDate}) => {

    const {analysisType} = useAnalysisType();

    const chosenParameter = useMemo(() => {
        const parameters = getAnalysisType(analysisType);
        return parameters.get('parameters').filter((parameter: any) => chosenAnalysis.includes(parameter.analysisType))
    }, [analysisType, chosenAnalysis]);


    const { handleSubmit, control, setError,  watch, formState: {errors}} = useForm<DiagnosisParameter>({
        defaultValues: {
            parameters: OCRResults && OCRResults?.length > 0 ? fillOCRValues(chosenParameter, OCRResults) : fillOCRValues(chosenParameter, defaultParameters ?? []),
        }
    });


    return (
        <Paper style={{padding: 16}}>
            <form onSubmit={handleSubmit(onSubmit, (errors) => {
                const elementError = Object.keys(errors)[0]
                if (elementError === 'parameters' && errors.parameters) {
                    const indexError = Object.keys(errors.parameters)[0]
                    const element = document.getElementById(`parameters.${indexError}`)
                    element?.scrollIntoView({behavior: 'smooth', block: 'center'});
                }
            })}>
                <Grid container spacing={1}>
                        <Fragment>
                            <PatientSummary sex={sex} date_of_birth={birthDate}/>
                            {chosenAnalysis.map((panel: string) => (
                                <ExaminationTable
                                    panel={panel}
                                    control={control}
                                    watch={watch}
                                    errors={errors}
                                    setError={setError}
                                    key={panel}
                                />
                            ))}
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    Naciskając przycisk: „DALEJ”, oświadczasz, że masz prawo do przekazania danych
                                    osobowych
                                    obejmujących dane dotyczące zdrowia, w szczególności wyrażasz dobrowolną zgodę na
                                    przetwarzanie tych danych (jeżeli są to Twoje dane osobowe) lub uzyskałeś zgodę na
                                    ich
                                    przetwarzanie od osoby, której dane dotyczą lub przekazujesz dane o charakterze
                                    hipotetycznym.

                                </Typography>
                                <Typography variant="body2" mt={1}>
                                    W przypadku gdy nie masz takiego prawa, zrezygnuj z dalszych czynności.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} mt={4}>
                                <Stepper/>
                            </Grid>
                        </Fragment>
                </Grid>
            </form>
        </Paper>
    )
}