import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Grid, Toolbar } from '@mui/material';
import { routes } from 'config';

import { UserContext } from 'contexts/UserContext/UserContext';
import { UserMenu } from 'shared/layout/UserMenu/UserMenu';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';


export const NavBar = () => {

    const { isLoggedIn } = useContext(UserContext);
    const { backToFirstPage } = useContext(LocalizationContext);

    const navigate = useNavigate();

    return (
        <AppBar>
            <Toolbar sx={{
                width: '100vw',
            }}>
                <Grid sx={{ cursor: 'pointer' }} onClick={() => {
                    backToFirstPage();
                    navigate(routes.interpretation);
                }}>
                    <img src='/assets/logo.svg' height="30px" width="100%" alt='logo'/>
                </Grid>
                {isLoggedIn && <UserMenu />}
            </Toolbar>
        </AppBar>
    )
}