import { useParams } from 'react-router-dom';
import { Container, Grid, Typography } from '@mui/material';
import { useFeedback } from 'shared/hooks/feedback/useFeedback';
import { Diagnosis } from 'views/LoggedIn/Diagnosis/Diagnosis';

export const InterpretationHistory = () => {

    const { feedbackId = '' } = useParams();
    const { feedback, status } = useFeedback({
        id: parseInt(feedbackId, 10),
    });


    if (status !== 'success') {
        return (
            <Typography>Loading...</Typography>
        )
    }

    return (
        <Container>
            <Grid container p={8}>
                {feedback && <Diagnosis diagnosis={feedback} />}
            </Grid>
        </Container>

    );
}