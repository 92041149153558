import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { UserContext } from 'contexts/UserContext/UserContext';

export const Authenticated: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

    const { isLoggedIn } = useContext(UserContext);

    if (isLoggedIn) {
        return (
            <Grid minHeight='70vh'>
                {children}
            </Grid>
        );
    }

    return null;
};

export const NotAuthenticated: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

    const { isLoggedIn } = useContext(UserContext);

    if (!isLoggedIn) {
        return (
            <Grid mt={2} minHeight='70vh'>
                {children}
            </Grid>
        );
    }
    return null;
};
