

import React, { FC, ReactNode, createContext } from 'react';
import { formDictionary } from './dict/formDict';
import { ContextProps } from './LocalizationContext.types';

const defaultContext: ContextProps = {
    formDict: formDictionary,
    activeStep: 0,
    loading: false,
    setLoading: () => {},
    handleNext: () => {},
    handleBack: () => {},
    handleNextSkipQuestions: () => {},
    handleBackSkipQuestions: () => {},
    backToFirstPage: () => {},
};


export const LocalizationContext = createContext(defaultContext);

export const LocalizationContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {

    const formDict = {...formDictionary};

    const [activeStep, setActiveStep] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    };

    const handleNextSkipQuestions = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
        setActiveStep(4);
    };

    const handleBackSkipQuestions = () => {
        setActiveStep(2);
    };

    const backToFirstPage = () => {
        setActiveStep(0);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    return (
        <LocalizationContext.Provider
            value={{
                loading,
                setLoading,
                formDict,
                activeStep,
                handleNext,
                handleBack,
                handleNextSkipQuestions,
                handleBackSkipQuestions,
                backToFirstPage,
            }}
        >
            {children}
        </LocalizationContext.Provider>
    );
};

