import {CustomParameter, OCRParameter} from 'clients/diagnosis/diagnosisClient.types';


export const fillOCRValues = (currentValues: CustomParameter[], OCRValues: OCRParameter[]) => {
    const parameters = [...currentValues];
    parameters.map((para) => {
        let match = OCRValues.find(value => value.parameter === para.name)
        if (match) {
            para.value_numeric = match.value_numeric
            para.low = match.low
            para.high = match.high
            para.unit = match.unit
            }
        return para
        }
    )
    return  parameters
}

export const setMarker = (value: any, low: any, high: any): string => {
    value = parseFloat(value);
    low = parseFloat(low);
    high = parseFloat(high);
    let marker = '-';
    if ((!!value ||  value===0) || (!!low || low===0) || (!!high || high===0)){
        if (value > high) marker='H'
        if (value < low) marker='L'
        if (value >= low && value <= high) marker='N'
        if (low > high ||  high < low) marker='!'
    }
    return marker
}

