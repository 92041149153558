import { request} from 'clients/client';
import { formatDate } from 'shared/helpers/date';
import { AnalysisTypeTypes } from 'shared/types/analysisType.types';
import { env } from '../../env';
import { GetParametersParams} from './analysisType.types';
import { OCRParameter } from 'clients/diagnosis/diagnosisClient.types';


const baseApiUrl = env.REACT_APP_API_URL;

const getAnalysisType = () => {
    return request({
        options: {
            url: `${baseApiUrl}analysis_type/`,
            method: 'GET',
        },
    }).then((data: any): AnalysisTypeTypes[] => data.data)
};

const getParameters = (params: GetParametersParams) => {
    return request({
        options: {
            url: `${baseApiUrl}extra_references/`,
            method: 'GET',
            params: {
                sex: params.sex,
                analysis_types: params.chosenAnalysis,
                birth_date: params.date_of_birth ? formatDate(params.date_of_birth) : '',
            }
        },
    }).then((data): OCRParameter[]  => data.data)
};

export const analysisTypeClient = {
    getAnalysisType,
    getParameters,
};