import React from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { QuestionAnswers } from 'clients/feedback/feedbackClient.types';

type Props = {
    answers: QuestionAnswers[]
}

export const MiddleLayerAnswers: React.FC<Props> = ({ answers }) => {

    return (
        <Grid>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Pytania</TableCell>
                            <TableCell>Odpowiedź</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {answers.map(({ question, answer}, index) => (
                            <TableRow>
                                <TableCell sx={{ textAlign: 'start', paddingLeft: 2 }}>{question}</TableCell>
                                <TableCell>{answer}</TableCell>
                            </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}