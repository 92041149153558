import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { muiTheme } from 'shared/styles/muiTheme';
import { Root } from 'views/Root';
import { UserContextProvider } from 'contexts/UserContext/UserContext';
import { LayoutContextProvider } from 'contexts/LayoutContext/LayoutContext';
import { LocalizationContextProvider } from 'contexts/LocalizationContext/LocalizationContext';

const App: React.FC = () => {
    const theme = muiTheme();

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                staleTime: 360000, // 6 minutes
            },
        },
    });


    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <QueryClientProvider client={queryClient} >
                <LocalizationContextProvider>
                    <UserContextProvider>
                        <LayoutContextProvider>
                            <ThemeProvider theme={theme}>
                                <CssBaseline/>
                                    <Root />
                            </ThemeProvider>
                        </LayoutContextProvider>
                    </UserContextProvider>
                </LocalizationContextProvider>
            </QueryClientProvider>
        </LocalizationProvider>

    )
}

export default App;
