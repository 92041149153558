import React from 'react';
import { Grid, CircularProgress } from '@mui/material';



export const Loading: React.FC = () => {

    return (
        <Grid display="flex" justifyContent="center">
            <CircularProgress />
        </Grid>

    )
}

