import { AxiosResponse } from 'axios';
import { request} from 'clients/client';
import { FeedbackDetail } from "clients/feedback/feedbackClient.types";
import {Answers, DiagnosisParameter, MiddleLayer, OCRResponse} from './diagnosisClient.types';
import { mapDiagnosisData } from './diagnosisClient.formatter';
import { env } from "../../env";


const ocrApiUrl = env.REACT_APP_OCR_API_URL;
const diagnosisApiUrl = env.REACT_APP_API_URL;
const OCR_API_KEY = env.REACT_APP_OCR_API_KEY

const postOCR = (file: File) => {
    const formData = new FormData()
    formData.append("attachment", file)

    return request({
        authenticate: false,
        options: {
            url: `${ocrApiUrl}ocr-lab-test/`,
            method: 'POST',
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                "X-Api-Key": OCR_API_KEY
            }
        },
    }).then((data: any): OCRResponse => data.data)

};

const createDiagnosis = (data: DiagnosisParameter) => {
    return request({
        options: {
            url: `${diagnosisApiUrl}blood_lab_test/`,
            method: 'POST',
            data: mapDiagnosisData(data),
        },
    }).then((data): FeedbackDetail  => data.data)
}

const getMiddleLayer = (link: string): Promise<AxiosResponse<MiddleLayer>> => {
    return request({
        options: {
            url: `${diagnosisApiUrl}patient_middle_layer/${link}/`,
            method: 'GET',
        },
    })
}

const createMiddleLayer = ({guid, data}: {guid: string, data: Answers}) => {
    return request({
        options: {
            url: `${diagnosisApiUrl}middle_layer_patient_answers/${guid}/`,
            method: 'POST',
            data: {"answers": data.answers.filter((answer) => answer.answer || answer.answer_numeric)},
        },
    }).then((data): FeedbackDetail  => data.data)
}

export const diagnosisClient = {
    postOCR,
    createDiagnosis,
    getMiddleLayer,
    createMiddleLayer,
};