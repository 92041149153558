import React, { useContext } from "react";
import {Grid, Paper, styled} from "@mui/material";
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import {LocalizationContext} from "../../../../contexts/LocalizationContext/LocalizationContext";

const StyledGrid = styled(Grid)<{
    active?: boolean;
}>(({ theme, active= true }) => ({
    display: 'flex',
    padding: '20px 4px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px solid #d2d8ff',
    borderBottom: active ? '4px solid red' : '1px solid #d2d8ff',
    color: '#01127e',
    opacity: active ? 1 : 0.5,
    fontWeight: active ? 800 : 500,
}));

export const InterpretationHeader = () => {

    const stepsHeader = [
        'Wybór Panelu',
        'Podstawowe dane Pacjenta',
        'Uzupełnij / sprawdź dane',
        'Dodatkowe pytania',
        'Interpretacja wyników'];
    const { isMobile } = useContext(LayoutContext);
    const { activeStep } = useContext(LocalizationContext);


    return (
        <Paper elevation={0}>
            <Grid container sx={{
                '&:first-of-type': {
                    borderLeft: '1px solid #d2d8ff',
                },
            }}>
                {!isMobile && stepsHeader.map((header, index) => (
                    <StyledGrid item active={index===activeStep} flexGrow={1} key={index} >
                        {index + 1}. {header}
                    </StyledGrid>
                ))}
                {isMobile && <StyledGrid item xs={12}> {stepsHeader[activeStep]} </StyledGrid>}
            </Grid>
        </Paper>
    )

}