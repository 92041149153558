import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Alert, Box, Button, Grid, Paper } from "@mui/material";
import { routes } from 'config';

export const Info: React.FC = () => {

    const { state } = useLocation();

    return (
        <Grid container sx={{ justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
            <Paper>
                <Box sx={{ padding: 10, textAlign: 'center', display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                    <Alert icon={false} severity="success">
                        {state.title}
                    </Alert>
                    <img alt='send' src='/assets/send.svg' style={{ height: 50, margin: 16 }}/>
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        component={RouterLink}
                        to={routes.login}
                    >
                        Wróć do Logowania
                    </Button>
                </Box>
            </Paper>
        </Grid>
    )
}