import React, {useCallback, useMemo, createContext, FC, ReactNode, useState, useReducer, useEffect} from 'react';
import { useQueryClient } from "@tanstack/react-query";
import jwt_decode from 'jwt-decode'

import { userClient } from 'clients/user/userClient';
import { Login, RefreshToken } from 'clients/user/userClient.types';
import { cacheKeys } from 'config';
import { ContextProps} from "./UserContext.types";
import { useMe}  from 'shared/hooks/user/useMe';
import {clearToken, getAccessToken, getRefreshToken} from 'shared/helpers/token';

export const defaultContext: ContextProps = {
    isLoggedIn: false,
    login: () => {},
    logout: () => {},
    formDictionary: {},
    userEmail: '',
    userId: '',
    user: {
        address: '',
        fieldOfStudy: '',
        firstName: '',
        gradeOfStudy: '',
        lastName: '',
        modeOfStudy: '',
        yearOfStudy: 0,
    },
    limits: [{
        package_code: '',
        limit: 0,
        used: 0,
        period: 'PER_DAY',
    }],
    forceChangePassword: false,

};

export const UserContext = createContext(defaultContext);
export const UserContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {

    const [logged, setLogged] = useState<boolean>()
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const queryClient = useQueryClient();

    const { user: userMe } = useMe( { enabled: !!logged });

    const isLoggedIn = useMemo(() => Object.keys(userMe || {}).length > 0 || logged, [userMe, logged]);
    const userId = useMemo(() => userMe?.url?.split('/').reverse()[1] || '', [userMe])
    const userEmail = useMemo(() => userMe?.email || '', [userMe])
    const forceChangePassword = useMemo(() => userMe?.forceChangePassword, [userMe])

    const limits = useMemo(() => userMe?.limits || [], [userMe])

    const login = async (data: Login) => {
        try {
            const response = await userClient.login(data);
            if (!!response.data){
                localStorage.setItem('accessToken', response.data.access);
                localStorage.setItem('refreshToken', response.data.refresh);
                setLogged(true)
            }
            return Promise.resolve({
                success: !!response,
            });
        } catch (error: any) {
            return Promise.reject({
                success: false,
                error: error.code,
                message: error.message,
            });
        }
    };

    const logout = useCallback(async () => {
        await queryClient.invalidateQueries([cacheKeys.user.getMe])
        queryClient.clear();
        clearToken();
        setLogged(false);
        forceUpdate()
    }, [queryClient]);

    const getNewTokens = useCallback(async (data: RefreshToken) => {
        try {
            const response = await userClient.refreshToken(data);
            return response
        } catch (err) {
            console.log(err)
        }
    }, [])

    useEffect(() => {
        const accessToken = getAccessToken();
        const refreshToken = getRefreshToken();

        if (!!accessToken && !!refreshToken) {
            let decodedToken = jwt_decode(accessToken);
            let currentDate = new Date();
            // @ts-ignore
            if (decodedToken.exp * 1000 > currentDate.getTime()) {
                setLogged(true);
            } else {
                getNewTokens({ "refresh": refreshToken}).then(
                    data => {
                        if (!!data?.data){
                            localStorage.setItem("accessToken", data?.data.access);
                        }
                        setLogged(true);
                    }).catch(() => setLogged(false))
            }
        } else {
            setLogged(false);
        }
    }, [getNewTokens])


    const formDictionary = {
        requiredField: 'To pole jest obowiązkowe',
        passwordRules: 'Hasło musi zawierać minimum 8 znaków, duże i małe litery, cyfry i znaki specjalne',
        universityEmail: 'Użyj adresu email swojej uczelni',
        firstNameField: 'Imię',
        lastNameField: 'Nazwisko',
        emailField: 'Adres email',
        repeatedEmailField: 'Powtórz adres email',
        password: 'Hasło',
        repeatedPassword: 'Powtórz Hasło',
    }

    return (
        <UserContext.Provider
            value={{
                isLoggedIn,
                login,
                logout,
                formDictionary,
                userEmail,
                user: userMe?.userProfile || defaultContext.user,
                userId,
                limits,
                forceChangePassword,
            }} >
            {children}
        </UserContext.Provider>
    );
}
