import React from 'react';
import { Grid, Typography } from '@mui/material';
import { common } from "@mui/material/colors";
import { useLocation } from 'react-router-dom';
import { HeaderData } from 'shared/data/headerData'

export const Header: React.FC = () => {

    const location = useLocation();

    return (
        <Grid container sx={{
            backgroundColor: '#0e2247',
            marginTop: 6,
            height: '140px',
            justifyContent: 'center',
            alignItems: 'center',
            paddingX: 10,
        }} >
            <img alt='logo' src='/assets/header.svg' style={{ position: 'absolute', height: '110px', width: '100%' }}/>
            <Typography variant="h2" color={common.white}> {HeaderData[location.pathname as string]} </Typography>
        </Grid>
    )
}
