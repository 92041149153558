import React, { useEffect, useRef } from 'react';
import {env} from "../../../env";

const RE_CAPTCHA = env.REACT_APP_RE_CAPTCHA_KEY || ""
const RE_CAPTCHA_SCRIPT = env.REACT_APP_RE_CAPTCHA_SCRIPT

const ReCaptchaComponent: React.FC = () => {
    const getCaptchaScriptElement = (): HTMLScriptElement | null => {
        const scriptElement = document.getElementById("re-captcha-root")
        if (!(scriptElement instanceof HTMLScriptElement))
            return null

        return scriptElement
    }

    const scriptRef = useRef<HTMLScriptElement>(getCaptchaScriptElement())


    useEffect(() => {
        loadScript()
    }, []);


    const loadScript = () => {
        let script = scriptRef.current
        if (script) {
            const queryString = `?render=${RE_CAPTCHA}`
            script.type = 'text/javascript'
            script.src = RE_CAPTCHA_SCRIPT + queryString
            script.async = true
            script.defer = true
        }

    }

    return (
        <React.Fragment/>
    )
}

export default ReCaptchaComponent