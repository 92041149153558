import React from 'react';
import { Box, Grid, Typography, TableHead, TableRow, TableCell, Table, TableContainer, TableBody } from '@mui/material';
import { PatientSummary } from './partials/PatientSummary';
import { LabTestType } from 'clients/diagnosis/diagnosisClient.types';
import { ExaminationSummary } from './partials/ExaminationSummary';

type Props = {
    sex: string;
    date_of_birth: string;
    labTests: LabTestType[];
}
export const Results: React.FC<Props> = ({
     sex='unknown',
     date_of_birth,
     labTests,
}) => {


    return (
        <Grid container spacing={2} maxWidth="xl">
            <Grid item xs={12}>
                <PatientSummary sex={sex} date_of_birth={date_of_birth}/>
            </Grid>
            <Grid item xs={12}>
                {labTests.map((test, index) => (
                    <Box mt={2} key={index}>
                        <Typography variant="h2" my={1}>{test.name}</Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Parameter</TableCell>
                                        <TableCell>Wynik</TableCell>
                                        <TableCell>Jednostka</TableCell>
                                        <TableCell>Zakres referencyjny</TableCell>
                                        <TableCell>LIV</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {test.lab_test_results.map((result, index) => (
                                        <ExaminationSummary key={index} results={result} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                ))}
            </Grid>
        </Grid>
    )

}