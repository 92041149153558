import React from 'react';
import { Grid, Link, Typography, List, ListItem } from '@mui/material';

export const RulesAndRegulations = () => {

    return (
        <Grid container justifyContent="center" p={8}>
            <Typography fontWeight={700} width={400} textAlign="center">
                I. Postanowienia ogólne
            </Typography>
            <Grid item  xs={12} mt={2}>
                <Typography fontWeight={700} textAlign="center">
                    § 1 Przedmiot Regulaminu
                </Typography>
                <List>
                    <ListItem>
                        Portal Bloodlab.pl dostępny na stronie edu.bloodlab.pl jest prowadzony przez Saventic Health sp. z o.o. z siedzibą w Toruniu przy ul. Polnej 66 lok. 12, 87-100 Toruń, wpisaną do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy w Toruniu VII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem: 0000758906, NIP: 8792707617 oraz REGON: 381859490.
                    </ListItem>
                    <ListItem>
                        Dane kontaktowe Usługodawcy są następujące:
                        <List>
                            <ListItem>
                                adres pocztowy: ul. Władysława Łokietka 5, 87-100 Toruń;
                            </ListItem>
                            <ListItem>
                                adres poczty elektronicznej:
                                <Link component="a" href="mailto:info@bloodlab.pl" color="inherit" ml={1}>
                                    info@bloodlab.pl
                                </Link>.
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem>
                        Niniejszy regulamin określa zasady korzystania z Portalu Bloodlab.pl przez studentów Uczelni.
                    </ListItem>
                </List>
            </Grid>
            <Grid item  xs={12} mt={2}>
                <Typography fontWeight={700} textAlign="center">
                    § 2 Definicje
                </Typography>
                <List>
                    <ListItem>
                        <strong>Interpretacja wyników badań laboratoryjnych</strong> – przypisanie danej kombinacji parametrów wynikających z wyników badań następujących informacji:
                        <List>
                            <ListItem>
                                opis nieprawidłowości;
                            </ListItem>
                            <ListItem>
                                rekomendowany wywiad;
                            </ListItem>
                            <ListItem>
                                rekomendowane badanie fizykalne;
                            </ListItem>
                            <ListItem>
                                możliwe stany chorobowe,
                            </ListItem>
                            <ListItem>
                                sugerowane postępowanie;
                            </ListItem>
                            <ListItem>
                                scenariusze kliniczne;
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem>
                        <strong>Portal Bloodlab</strong> – portal internetowy udostępniany Użytkownikom na stronie internetowej: edu.bloodlab.pl prowadzony przez Usługodawcę służący do wykonywania Interpretacji wyników badań laboratoryjnych w celach edukacyjnych;
                    </ListItem>
                    <ListItem>
                        <strong>Regulamin</strong> – niniejszy regulamin Portalu Bloodlab;
                    </ListItem>
                    <ListItem>
                        <strong>Użytkownik</strong> – osoba wpisana na listę studentów 4, 5 lub 6-go roku na kierunku lekarskim Uczelni, który korzysta z Portalu Bloodlab;
                    </ListItem>
                    <ListItem>
                        <strong>Uczelnia</strong> – szkoła wyższa wg ustawy z dnia 20 lipca 2018 r Prawo o szkolnictwie wyższym i nauce, która prowadzi kształcenie na kierunku lekarskim oraz posiada umowę z Usługodawcą w sprawie korzystania przez Użytkowników z Portalu Bloodlab;
                    </ListItem>
                    <ListItem>
                        <strong>Usługa</strong> – automatyczna, algorytmiczna oraz spersonalizowana Interpretacja wyników badań laboratoryjnych;
                    </ListItem>
                    <ListItem>
                        <strong>Usługodawca </strong> – Saventic Health Sp. z o.o. z siedzibą w Toruniu przy ul. Polnej 66 lok 12, wpisana do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy w Toruniu VII Wydział Gospodarczy pod numerem: 0000758906, NIP: 8792707617, REGON: 381859490.
                    </ListItem>
                </List>
            </Grid>
            <Grid item  xs={12} mt={2}>
                <Typography fontWeight={700} textAlign="center">
                    § 3 Minimalne wymagania techniczne
                </Typography>
                <List>
                    <ListItem>
                        Do korzystania z Portalu Bloodlab niezbędne jest spełnienie następujących minimalnych wymagań technicznych:
                        <List>
                            <ListItem>
                                posiadanie komputera z dostępem do sieci Internet z rekomendowanym systemem operacyjnym Windows 10 i Mac OS 10.10. lub minimalnym systemem operacyjnym Windows 7 albo urządzenia mobilnego z systemem Android lub iOS;                            </ListItem>
                            <ListItem>
                                posiadanie zainstalowanej jednej z przeglądarek internetowych obsługującej technologię JavaScript i cookies:
                                <List>
                                    <ListItem>
                                        Microsoft Edge począwszy od wersji 85;
                                    </ListItem>
                                    <ListItem>
                                        Mozilla Firefox począwszy od wersji 70;
                                    </ListItem>
                                    <ListItem>
                                        Google Chrome począwszy od wersji 70;
                                    </ListItem>
                                    <ListItem>
                                        Opera począwszy od wersji 70;
                                    </ListItem>
                                    <ListItem>
                                        Safari począwszy od wersji 14;
                                    </ListItem>
                                </List>
                            </ListItem>

                        </List>
                    </ListItem>
                    <ListItem>
                        W celu zapewnienia bezpieczeństwa świadczenia Usługi oraz przesyłu danych, Usługodawca podejmuje środki, w szczególności techniczne, adekwatne  do zagrożenia, w szczególności środki służące zapobieganiu pozyskania i modyfikacji przez osoby nieuprawnione danych osobowych przesyłanych przez Internet, w tym w szczególności stosowanie protokołu SSL (Secure Socket Layer).
                    </ListItem>
                </List>
            </Grid>
            <Grid item  xs={12} mt={2}>
                <Typography fontWeight={700} textAlign="center">
                    § 4 Zobowiązania Użytkownika
                </Typography>
                Użytkownik jest zobowiązany do:
                <List>
                    <ListItem>
                        korzystania z Portalu Bloodlab w sposób zgodny z obowiązującymi przepisami prawa, postanowieniami Regulaminu, dobrymi obyczajami oraz z poszanowaniem dóbr osobistych innych podmiotów oraz 	w sposób niezakłócający jego funkcjonowania;
                    </ListItem>
                    <ListItem>
                        korzystania z wszelkich treści zamieszczonych w ramach Portalu Bloodlab jedynie w zakresie własnego użytku w celach edukacyjnych oraz do nieudostępniania Usługi osobom trzecim, w tym innym studentom, członkom rodziny, znajomym, ani niewykorzystywania Portalu Bloodlab w innym celu niż edukacyjny, w szczególności w celu świadczenia jakichkolwiek usług, w tym nieodpłatnych, na rzecz jakichkolwiek osób.
                    </ListItem>
                </List>
            </Grid>
            <Typography fontWeight={700} width={400} textAlign="center">
                II. Zasady korzystania z Portalu Bloodlab
            </Typography>
            <Grid item  xs={12} mt={2}>
                <Typography fontWeight={700} textAlign="center">
                    § 5 Zasady korzystania z Usługi
                </Typography>
                <List>
                    <ListItem>
                        Użytkownicy mają prawo do nieodpłatnego korzystania z Usługi w ramach umowy o wzajemnej współpracy zawartej pomiędzy Uczelnią, a Usługodawcą, na podstawie której Użytkownicy mogą wysyłać zapytania zawierające wyniki badań laboratoryjnych oraz otrzymywać zwrotnie dostęp do Interpretacji wyników badań laboratoryjnych, z zastrzeżeniem, że korzystanie z Usługi jest dozwolone wyłącznie w celach edukacyjnych.
                    </ListItem>
                    <ListItem>
                        Użytkownik może:
                        <List>
                            <ListItem>
                                wpisać ręcznie hipotetyczny wynik badania laboratoryjnego lub
                            </ListItem>
                            <ListItem>
                                skorzystać z rzeczywistego wyniku badania, np. omawianego podczas zajęć lub praktyk studenckich, jeżeli posiada prawo do wykorzystania takiego wyniku na Portalu Bloodlab, w szczególności uzyskał zgodę od osoby, której wynik dotyczy.
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem>
                        Interpretacje wyników badań laboratoryjnych nie zastępują konsultacji lekarskich. Uzyskane Interpretacje wyników badań laboratoryjnych stanowią system wsparcia decyzji lekarskiej stworzony przez hematologów dla lekarzy innych specjalności oraz studentów nauk medycznych mający na celu ułatwienie procesu diagnostycznego pacjentów oraz poszerzenie wiedzy z zakresu hematologii. Z uwagi na fakt, iż System BloodLab korzysta z ograniczonej ilości danych, zawarte w Interpretacji wyników badań laboratoryjnych informacje mają charakter wyłącznie podpowiedzi, nie stanowiąc wytycznych lekarskich. Zatem po uzyskaniu Interpretacji wyników badań laboratoryjnych zaleca się skorzystanie z konsultacji lekarskiej. Lekarz może zalecić m.in. powtórzenie badań, czy rozszerzenie diagnostyki.
                    </ListItem>
                    <ListItem>
                        Usługodawca nie ponosi odpowiedzialności za decyzje oraz następstwa działań podejmowanych przez Użytkowników, jak również przez inne osoby w oparciu o Interpretację wyników badań dokonaną z wykorzystaniem Produktu, w szczególności za rozpoczęcie, zaprzestanie lub zmianę sposobu leczenia lub rehabilitacji, w tym zmiany w zakresie przyjmowanych leków lub suplementów diety, które to czynności powinny zostać każdorazowo uprzednio skonsultowane z lekarzem, lub rekomendowanie takich działań innym na podstawie Interpretacji wyników badań laboratoryjnych.
                    </ListItem>
                </List>
            </Grid>
            <Grid item  xs={12} mt={2}>
                <Typography fontWeight={700} textAlign="center">
                    § 6 Warunki korzystania z Usługi przez Użytkowników
                </Typography>
                <List>
                    <ListItem>
                        W celu skorzystania przez Użytkownika z Usługi należy założyć konto, co wymaga podjęcia następujących czynności:
                        <List>
                            <ListItem>
                                złożenie oświadczenia, iż osoba zakładająca konto spełnia przesłanki Użytkownika w rozumieniu Regulaminu (jest studentem 4, 5 lub 6 roku studiów kierunku lekarskiego Uczelni);
                            </ListItem>
                            <ListItem>
                                zapoznanie się i zaakceptowanie Regulaminu;
                            </ListItem>
                            <ListItem>
                                podanie danych: imię i nazwisko, adres poczty elektronicznej Użytkownika (z zastrzeżeniem, że rejestracja może nastąpić tylko z wykorzystaniem adresu poczty elektronicznej Użytkownika w domenie Uczelni);
                            </ListItem>
                            <ListItem>
                                wyrażenie zgodę na przetwarzanie danych osobowych wskazanych w pkt. 3;
                            </ListItem>
                            <ListItem>
                                z uwagi na fakt, iż w ramach korzystania z Portalu Bloodlab Użytkownik może wprowadzić rzeczywiste wyniki badań laboratoryjnych, jednakże wyłącznie pod warunkiem, że posiada do tego prawo – złożenie oświadczenia, zgodnie z którym w przypadku podawania rzeczywistych wyników badań laboratoryjnych Użytkownik zobowiązuje się do podawania na Portalu Bloodlab wyłącznie danych, do których ma prawo, w szczególności uzyskał zgodę od osób, których dane dotyczą.
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem>
                        W celu uzyskania Interpretacji wyników badań laboratoryjnych należy:
                        <List>
                            <ListItem>
                                wybrać rodzaj Usługi (wybrany panel badań do interpretacji);
                            </ListItem>
                            <ListItem>
                                podać dane niezbędne do uzyskania Interpretacji wyników badań laboratoryjnych: wiek (ustalany na podstawie miesiąca i roku urodzenia), płeć, datę wykonania badań laboratoryjnych, wskazane w formularzu parametry badań laboratoryjnych oraz informacje o stanie zdrowia stanowiące odpowiedzi na zadane pytania;
                            </ListItem>
                            <ListItem>
                                potwierdzić, iż Użytkownik posiada prawo do wykorzystania wprowadzonych danych o stanie zdrowia w celu uzyskania Interpretacji wyników badań laboratoryjnych oraz zatwierdzić wprowadzone dane przez naciśnięcie przycisku: Wyślij.
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem>
                        W trakcie procedury korzystania z Usługi – aż do momentu naciśnięcia przycisku Wyślij, Użytkownik ma możliwość modyfikowania wprowadzonych danych.
                    </ListItem>
                    <ListItem>
                        Interpretacji wyników badań laboratoryjnych jest dostępna wyłącznie online. Użytkownik nie otrzymuje osobnego pliku zawierającego raport z Interpretacją.
                    </ListItem>
                </List>
            </Grid>
            <Grid item  xs={12} mt={2}>
                <Typography fontWeight={700} textAlign="center">
                    § 7 Dane osobowe
                </Typography>
                <List>
                    <ListItem>
                        Administratorem danych osobowych jest Saventic Health sp. z o.o. z siedzibą w Toruniu przy ul. Polnej 66 lok 12, 87-100 Toruń, wpisaną do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy w Toruniu VII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem: 0000758906, NIP: 8792707617 oraz REGON: 381859490.
                    </ListItem>
                    <ListItem>
                        Szczegółowe postanowienia dotyczące ochrony danych osobowych i polityki prywatności znajdują się w Polityce prywatności, która dostępna jest na stronie internetowej Portalu Bloodlab w zakładce Polityka prywatności pod adresem: edu.bloodlab.pl.
                    </ListItem>
                </List>
            </Grid>
            <Grid item  xs={12} mt={2}>
                <Typography fontWeight={700} textAlign="center">
                    § 8 Postanowienia końcowe
                </Typography>
                <List>
                    <ListItem>
                        Regulamin dostępny jest nieodpłatnie na stronie internetowej Portalu Bloodlab w zakładce Regulamin pod adresem: edu.bloodlab.pl  oraz w siedzibie Usługodawcy. Regulamin jest udostępniony na wyżej wskazanej stronie internetowej w formie, która umożliwia pozyskanie, odtwarzanie i utrwalanie treści niniejszego Regulaminu za pomocą systemu teleinformatycznego, którym posługuje się Użytkownik.
                    </ListItem>
                    <ListItem>
                        Usługodawca zastrzega sobie prawo do dokonywania zmiany Regulaminu z przyczyn prawnych lub organizacyjnych. O treści zmian Regulaminu Użytkownicy są informowani przez umieszczenie pod adresem Portalu Bloodlab: edu.bloodlab.pl nowej, zmienionej treści Regulaminu.
                    </ListItem>
                    <ListItem>
                        Zmiany Regulaminu wchodzą w życie z dniem zamieszczenia nowej wersji lub w terminie podanym wraz z informacją o  zmianie Regulaminu.
                    </ListItem>
                    <ListItem>
                        W sprawach nieuregulowanych w niniejszym Regulaminie mają zastosowanie przepisy prawa polskiego.
                    </ListItem>
                    <ListItem>
                        Niniejszy Regulamin wchodzi w życie z dniem 2023-01-27 i obowiązuje w odniesieniu do rozpoczęcia korzystania z Usługi od tego dnia.
                    </ListItem>
                </List>
            </Grid>

        </Grid>

    )
}