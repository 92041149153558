import React, {useCallback, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { userClient } from 'clients/user/userClient'
import { routes } from 'config';
import ReCaptchaComponent from 'shared/components/reCaptcha/ReCaptchaComponent';
import { UserAuthForm } from './partials/AuthForm';
import {LayoutContext} from "../../contexts/LayoutContext/LayoutContext";

interface ResetPasswordType {
    email: string;
}

export const ResetPassword = () => {
    const navigate = useNavigate();
    const { isMobile } = useContext(LayoutContext);
    const { genericSnackbar } = useContext(LayoutContext);


    const onSubmit = useCallback( async(data: ResetPasswordType) => {
        try {
            await userClient.resetPassword(data)
            navigate(routes.information, {
                state: {
                    title: 'W przypadku istnienia konta o podanym adresie została wysłana na niego wiadomość'
                },
            })
        } catch (err){
            genericSnackbar('Coś poszło nie tak', 'error');

        }

    }, [navigate, genericSnackbar])


    return (
        <Grid container sx={{ justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
            <ReCaptchaComponent/>
            <Paper>
                <Box p={isMobile ? 2 : 10} textAlign="center">
                    <Grid item mb={3} xs={12} textAlign="center">
                        <img src='/assets/logo.svg' height="40px" width="100%" alt='logo'/>
                        <Typography variant="h2" maxWidth={500} my={2}
                                    sx={theme => ({color: theme.palette.primary.main})}>
                            Aby zresetować swoje hasło wprowadź swój adres emial i kliknij "Zresetuj Hasło"
                        </Typography>
                        <Typography variant="body2" maxWidth={500}>
                            Na twój adres email wyślemy Ci nowe hasło oraz link w celu potwierdzenia zmiany hasła
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <UserAuthForm
                            onSubmitRequest={onSubmit}
                            onSubmitButtonText="Zresetuj Hasło"
                            hiddenFields={['firstName', 'lastName', 'repeatedEmail','password', 'repeatedPassword', 'oldPassword']}
                        />
                    </Grid>
                </Box>
            </Paper>
        </Grid>
    )
}