import { AxiosError, AxiosResponse } from "axios";
import { request } from 'clients/client';
import { PaginatedResults} from "shared/helpers/Query";
import { env} from "../../env";
import { formatDate } from "shared/helpers/date";
import { Feedback, FeedbackDetail, GetFeedbackParams, GetFeedbacksParams} from './feedbackClient.types';

const baseApiUrl = env.REACT_APP_API_URL;

const getFeedbacks = (params: GetFeedbacksParams): Promise<AxiosResponse<PaginatedResults<Feedback>, AxiosError>>  => {
    return request({
        options: {
            url: `${baseApiUrl}blood_lab_test/my_lab_tests/`,
            method: 'GET',
            params: {
                page: params.page,
                sex: params.sex,
                date_of_birth_after: params.dateOfBirthAfter ? formatDate(params.dateOfBirthAfter) : '',
                date_of_birth_before: params.dateOfBirthBefore ? formatDate(params.dateOfBirthBefore) : '',

            }

        },
    })
};

const getFeedback = (params: GetFeedbackParams) => {
    return request({
        options: {
            url: `${baseApiUrl}blood_lab_test/${params.id}`,
            method: 'GET',
        },
    }).then((data): FeedbackDetail => data.data)
};

export const feedbackClient = {
    getFeedbacks,
    getFeedback,
}