import React, { useMemo } from 'react';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Grid, styled, TableCell, TableRow } from '@mui/material';
import { LabTestResult } from 'clients/diagnosis/diagnosisClient.types';
import { setMarker} from 'shared/helpers/form';

const StyledCell = styled(TableCell)<{
    marker?: string;
    rightOpen?: boolean;
}>(({ theme, marker }) => ({
    padding: 4,
    color: (marker === 'L' || marker === 'H') ? 'red' : 'inherit',
    fontWeight: (marker === 'L' || marker === 'H') ? 600 : 'inherit',
}));

type Props = {
    results: LabTestResult;
}
export const ExaminationSummary: React.FC<Props> = ({results}) => {

    const marker = useMemo(() => {
        return setMarker(results.value_numeric, results.low, results.high)
    }, [results])

    return (
        <TableRow>
            <StyledCell marker={marker}>{results.parameter}</StyledCell>
            <StyledCell marker={marker}>{results.unit}</StyledCell>
            <StyledCell marker={marker}>{results.value_numeric}</StyledCell>
            <StyledCell marker={marker}>{results.low} - {results.high}</StyledCell>
            <StyledCell marker={marker}>
                <Grid item display="flex" justifyContent="center">
                    {marker}
                    {marker === 'L' && <ArrowDownward fontSize="small"/>}
                    {marker === 'H' && <ArrowUpward fontSize="small"/>}
                </Grid>

            </StyledCell>
        </TableRow>
    );
}