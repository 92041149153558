import React, {Fragment, useEffect, useMemo} from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import {
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    styled, TextField,
    Typography
} from '@mui/material';
import {Answers, Question} from 'clients/diagnosis/diagnosisClient.types';
import { useMiddleLayer } from 'shared/hooks/middleLayer/useMiddleLayer';
import { Stepper } from 'views/LoggedIn/Interpretation/partials/Stepper';
import { Loading } from 'shared/components/loading/Loading';

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: 13,
    marginBottom: 8,
    '&.MuiFormLabel-root.Mui-focused': {
        color: theme.palette.secondary.main,
    },
    fontFamily: 'Montserrat SemiBold',
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    color: theme.palette.secondary.main,
    marginLeft: 8,
    '& .MuiTypography-root': {
        fontSize: 13,
        fontFamily: 'Montserrat Regular',
    },

}));

type Props = {
    link: string;
    onSubmit: (values: any) => void;

}

export const MiddleLayer: React.FC<Props> = ({link, onSubmit}) => {

    const { questions, name, status } = useMiddleLayer(link);
    let count = 0;

    const allQuestions = useMemo(() => {
        let allQuestions: Question[] = []
        questions?.map((questionGroup) => questionGroup).map((question) => {
            return allQuestions.push.apply(allQuestions, question.questions);
       })
        return allQuestions
    }, [questions])


    const { control, setValue, watch, handleSubmit, formState: { errors }  } = useForm<Answers>({
        defaultValues: {
            answers: allQuestions.map(({id}) => ({ question: id })) || [],
        }
    });

    const { fields } = useFieldArray({
        control,
        name: "answers"
    });

    useEffect(() => {
        const questions = allQuestions.map(({id}) => ({ question: id }))
        setValue('answers', questions)
    }, [allQuestions, setValue])

    const fullAnswers = watch('answers');

    const unhide = (is_hidden: boolean, unhide_id: number, unhide_answers_id: number[]) => {
        if (!is_hidden) {
            return true
        }
        const checkAnswer = fullAnswers.filter(answer => answer.question === unhide_id)
        // @ts-ignore
        return unhide_answers_id.includes(checkAnswer[0].answer)
    }

    if (status === 'loading') {
        return (
            <Loading />
        )
    }

    return (
        <Paper>
            {status === 'success' && questions && questions?.length > 0 && fields.length > 0 &&
                <Grid container p={2}>
                    <Typography variant='h2' p={1} sx={theme => ({color: theme.palette.secondary.main})}>
                        Odpowiedz na poniższe pytania
                    </Typography>
                    <Grid item xs={12} my={2}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12} p={1}>
                        <Typography sx={theme => ({color: theme.palette.error.main})}>
                            {name}
                        </Typography>
                    </Grid>
                    <form onSubmit={handleSubmit(onSubmit, (errors) => {
                        if (errors.answers) {
                            const indexError = Object.keys(errors.answers)[0];
                            const element = document.getElementById(`answers.${indexError}.answer`);
                            element?.scrollIntoView({behavior: 'smooth', block: 'center'});

                        }
                    })}>
                        {allQuestions.map((question, index) => {
                            const hidden = unhide(question.is_hidden, question.unhide_by, question.unhide_by_answer)
                            if (hidden) {
                                count = count + 1
                            }
                            let no = count
                            return (
                                <Fragment key={index}>
                                    { hidden &&
                                        <Grid item xs={12} p={1} id={`answers.${index}.answer`}>
                                            <FormControl component="fieldset">
                                                <StyledFormLabel error={!!errors?.answers?.[index]?.answer}>
                                                    {no}. {question.question_full}
                                                </StyledFormLabel>
                                                {question.input_type === 'select' && <Controller
                                                    name={`answers.${index}.answer`}
                                                    rules={{ required: question.required && 'To Pole jest wymagane'}}
                                                    control={control}
                                                    render={({field}) => (
                                                        <RadioGroup
                                                            {...field}
                                                            name={question.question_full}
                                                            onChange={(event, value) => {
                                                                // By default value is string so we need to overwrite it
                                                                const intValue = parseInt(value)
                                                                setValue(`answers.${index}.answer`, intValue)
                                                            }}
                                                        >
                                                            {question.answers.map(({id, answer}) => (
                                                                <StyledFormControlLabel
                                                                    value={id}
                                                                    control={
                                                                        <Radio
                                                                            icon={<RadioButton/>}
                                                                            checkedIcon={<RadioButton checked/>}
                                                                        />
                                                                    }
                                                                    label={answer} />
                                                            ))}
                                                    </RadioGroup>
                                                    )}
                                                />}
                                                {question.input_type === 'numeric' && <Controller
                                                    name={`answers.${index}.answer_numeric`}
                                                    rules={{ required: question.required && 'To Pole jest wymagane'}}
                                                    control={control}
                                                    render={({field}) => (
                                                        <TextField
                                                            {...field}
                                                            type="number"
                                                            variant="standard"
                                                            sx={{
                                                                '& .MuiInputBase-input': {
                                                                    padding: '1px 8px',
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />}
                                            </FormControl>
                                        </Grid>
                                    }
                                </Fragment>
                            );
                        })}
                        <Grid item xs={12} mt={2}>
                            <Stepper />
                        </Grid>
                    </form>
                </Grid>
                }
        </Paper>
    )
}

type PropsRadioButton = {
    checked?: boolean;
}
const RadioButton: React.FC<PropsRadioButton> = ({checked}) => (
    <svg
        width="16px"
        height="16px"
        viewBox="0 0 24 24"
        fontSize="38px">
        <circle
            cx="50%"
            cy="50%"
            r="11px"
            stroke="#D2D8FF"
            strokeWidth="1px"
            fill="none"
        />
        {checked && (
            <circle
                cx="50%"
                cy="50%"
                r="6px"
                fill="red"
            />
        )}
    </svg>
);