import React, {Fragment, useEffect} from 'react';
import {Grid, Pagination} from '@mui/material';
import { useFeedbacks } from 'shared/hooks/feedback/useFeedbacks';
import { Feedback } from 'clients/feedback/feedbackClient.types';
import { InterpretationHistoryItem } from './parials/InterpreatationHistoryItem';
import {usePagination} from "../../../shared/hooks/usePagination";
import {FiltersType, InterpretationHistoryFilters} from "./parials/InterpretationHistoryFilters";
import {Loading} from "../../../shared/components/loading/Loading";


export const InterpretationHistories = () => {

    return (
        <InterpretationHistoryFilters>
            {({ filters }) => (
                    <InterpretationHistoryList filters={filters} />
                )}
        </InterpretationHistoryFilters>
    )
}

type Filters = {
    filters: FiltersType
}
export const InterpretationHistoryList: React.FC<Filters> = ({ filters }) => {


    const { page, setPage, totalPages, setCount } = usePagination();
    const { feedbacks, count, status } = useFeedbacks({
        page: page,
        ...filters
    });

    useEffect(() => {
        if (status === 'success') {
            setCount(count);
        }
    }, [status, setCount, count]);

    useEffect(() => {
        setPage(1)
    }, [filters, setPage]);



    if (status === 'loading') {
        return (
            <Loading />
        )
    }

    return (
        <Grid container spacing={1} maxWidth="lg" mt={2} justifyContent="start">
            {status === 'success' && (
                <Fragment>
                    {feedbacks.map((feedback: Feedback, index: number) => (
                        <Grid item xs={12} sm={6} key={index}>
                            <InterpretationHistoryItem feedback={feedback}/>
                        </Grid>
                    ))}
                </Fragment>
            )}
            <Grid item xs={12} display="flex" justifyContent="center">
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(event, value) => {
                        setPage(value);
                    }}
                    showFirstButton={page !== 1}
                    showLastButton={page !== totalPages}
                />
            </Grid>
        </Grid>
    )
}

