import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { cacheKeys } from 'config';
import { userClient } from 'clients/user/userClient';

type Options = {
    enabled: boolean
};

const defaultOptions: Partial<Options> = {
    enabled: true,
};

export type Params = {
    code: string;
};

export const useVerifyEmail = (params: Params, options: Partial<Options> = defaultOptions) => {
    const { data = {}, status, error, isLoading } = useQuery(
        [cacheKeys.getVerifyEmail, params],
        () => userClient.verifyEmail(params),
        { enabled: options.enabled },
    );

    return {
        status,
        isLoading,
        error: error as AxiosError,
        data: data || {},
    };
};
