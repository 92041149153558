export const formDictionary = {
    requiredField: 'To pole jest obowiązkowe',
    passwordRules: 'Hasło musi zawierać minimum 8 znaków, duże i małe litery, cyfry i znaki specjalne',
    emailRules: 'To nie jest prawidłowy adres email',
    universityEmail: 'Użyj adresu email swojej uczelni',
    firstNameField: 'Imię',
    lastNameField: 'Nazwisko',
    emailField: 'Adres email',
    repeatedEmailField: 'Powtórz adres email',
    newPasswordField: 'Nowe Hasło',
    passwordField: 'Hasło',
    oldPasswordField: 'Stare Hasło',
    repeatedPasswordField: 'Powtórz Hasło',
    passwordsDoNotMatch: 'Hasła nie są jednakowe'
}