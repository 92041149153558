import React, { useContext } from 'react';
import { styled, MobileStepper }  from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';

const StyledButton = styled(LoadingButton)<{
    disabled?: boolean;
}>(({ theme, disabled }) => ({
    backgroundColor: disabled ? 'lightgray' : '#f51d44',
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
    },
    '& .MuiCircularProgress-root': {
        color: '#FFF',
    }
}));

export const Stepper = () => {

    const { activeStep, loading, handleBack, handleBackSkipQuestions } = useContext(LocalizationContext);
    const maxSteps = 5
    const getBack = () => {
        window.scrollTo(0, 0);
        if (activeStep === 4) {
            handleBackSkipQuestions()
        } else {
            handleBack();

        }
    }

    return (
        <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
                <StyledButton
                    size="medium"
                    type="submit"
                    disabled={activeStep === maxSteps - 1}
                    loading={loading}
                >
                    DALEJ <KeyboardArrowRight />
                </StyledButton>
            }
            backButton={
                <StyledButton size="medium" onClick={getBack} disabled={activeStep === 0}>
                    <KeyboardArrowLeft />
                    POWRÓT
                </StyledButton>
            }
        />

    )
}
