import React, { useContext } from 'react';
import { Button, ButtonGroup, Grid, Paper, styled, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { common, grey } from '@mui/material/colors';
import { Female, Male } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { DiagnosisParameter } from 'clients/diagnosis/diagnosisClient.types';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { Stepper } from '../Interpretation/partials/Stepper';

type Props = {
    onSubmit: (values: any) => void;
}

const ContainedButtonGroup = styled(ButtonGroup)<{
    mobile: boolean,
}>(({theme, mobile}) => ({
    width: mobile ? '100%' : '60%',
    '& .MuiButtonGroup-grouped.MuiButtonBase-root': {
        minWidth: 100,
        minHeight: 80,
        borderColor: theme.palette.error.main,
        '&.Mui-selected': {
            backgroundColor: theme.palette.error.main,
        },
        '&:hover': {
            color: theme.palette.error.main,
            backgroundColor: grey['200']
        },
    },
}));

export const BasicPatientData: React.FC<Props> = ({onSubmit}) => {

    const {isMobile} = useContext(LayoutContext);
    const {formDict} = useContext(LocalizationContext);

    const {
        handleSubmit,
        control,
        getValues,
        setValue,
        watch,
        formState: {errors}
    } = useForm<Omit<DiagnosisParameter, 'parameters'>>({
        defaultValues: {
            sex: undefined,
            date_of_birth: null,
            test_date: new Date(),
        }
    });

    const sexWatch = watch('sex');

    return (
        <Paper style={{padding: 16}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item xs={12} sm={6} justifyContent="center" id='sex' p={2}>
                        <Controller
                            rules={{required: true}}
                            control={control}
                            name="sex"
                            defaultValue={''}
                            render={({field}) => (
                                <ContainedButtonGroup {...field} mobile={isMobile}>
                                    <Button
                                        value="m"
                                        onClick={() => setValue('sex', 'm')}
                                        sx={theme => ({
                                            width: '100%',
                                            color: sexWatch === 'm' ? common.white : theme.palette.error.main,
                                            backgroundColor: sexWatch === 'm' ? theme.palette.error.main : common.white,
                                        })}
                                    >
                                        <Male/>
                                        Mężczyzna
                                    </Button>
                                    <Button
                                        value="f"
                                        onClick={() => setValue('sex', 'f')}
                                        sx={theme => ({
                                            width: '100%',
                                            color: sexWatch === 'f' ? common.white : theme.palette.error.main,
                                            backgroundColor: sexWatch === 'f' ? theme.palette.error.main : common.white,
                                        })}
                                    >
                                        <Female/>
                                        Kobieta
                                    </Button>
                                </ContainedButtonGroup>
                            )}
                        />
                        {errors.sex && !getValues('sex') &&
                            <Typography color='error' fontSize='0.75rem' sx={{margin: '3px 14px 0px'}}>
                                Proszę wybrać płeć
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={12} sm={3} id='date_of_birth' p={2}>
                        <Controller
                            name='date_of_birth'
                            rules={{
                                required: formDict.requiredField,
                                validate: date => date?.toString() === 'Invalid Date' ? 'Nieprawidłowy format daty' : true
                            }}
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    inputFormat="MM.yyyy"
                                    label="Data Urodzenia"
                                    views={['year', 'month']}
                                    mask="__.__.____"
                                    showDaysOutsideCurrentMonth
                                    maxDate={new Date()}
                                    openTo="year"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={!!errors?.date_of_birth}
                                            helperText={errors?.date_of_birth?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} p={2}>
                        <Controller
                            name='test_date'
                            control={control}
                            render={({field}) => (
                                <DatePicker
                                    {...field}
                                    inputFormat="dd.MM.yyyy"
                                    label="Data Badania"
                                    openTo="year"
                                    maxDate={new Date()}
                                    showDaysOutsideCurrentMonth
                                    renderInput={(params) => <TextField {...params} helperText={null}/>}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Stepper/>
            </form>
        </Paper>
    )
}