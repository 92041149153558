import { useQuery} from "@tanstack/react-query";
import { cacheKeys} from 'config';
import { diagnosisClient } from 'clients/diagnosis/diagnosisClient';

export const useMiddleLayer = (link: string) => {

    const {data: { data: middleLayer } = {}, status } = useQuery(
        [cacheKeys.middleLayer.getMiddleLayer],
        () => diagnosisClient.getMiddleLayer(link),
    );

    return {
        questions: middleLayer?.middle_layer[0]?.middle_layer_question_group,
        name: middleLayer?.middle_layer[0]?.name,
        status,
    };
};
