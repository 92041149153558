import { useQuery } from '@tanstack/react-query';
import { cacheKeys } from 'config';
import { userClient } from 'clients/user/userClient';

type Options = {
    enabled: boolean
};

const defaultOptions: Partial<Options> = {
    enabled: true,
};

export const useMe = (options: Partial<Options> = defaultOptions) => {
    const { data: { data: user } = {}, status: loadingStatus, fetchStatus, error } = useQuery(
        [cacheKeys.user.getMe],
        userClient.getMe,
        { enabled: options.enabled },
    );

    return {
        loadingStatus,
        fetchStatus,
        error,
        user: user,
    };
};
