import React, { Fragment, useContext, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Alert, Checkbox, Grid, Button, Typography, Paper, styled, FormControlLabel } from '@mui/material';
import { LayoutContext} from 'contexts/LayoutContext/LayoutContext';
import { UserContext } from 'contexts/UserContext/UserContext';
import { Loading } from 'shared/components/loading/Loading';
import { Stepper } from 'views/LoggedIn/Interpretation/partials/Stepper';
import { useAnalysisType } from 'shared/hooks/analysisType/useAnalysisType'

type Props = {
    onSubmit: (values: any) => void;
}

const ContainedAnalysisPanel = styled(Grid)<{
    tablet: boolean;
}>(({ theme, tablet }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    maxWidth: tablet? '100%' : '65%',
}));


const StyledFormControlLabel = styled(FormControlLabel)<{
    index: number;
    mobile: boolean;
    tablet: boolean;
}>(({ theme, index, mobile, tablet }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border:' 1px solid transparent',
    boxShadow: '0px 0px 10px -2px rgba(0, 0, 0, 0.2)',
    width: tablet ? '100%' : 200,
    textAlign: 'center',
    minHeight: mobile ? 120 : 200,
    "&:hover": {
      boxShadow: '0px 0px 12px -2px rgba(0, 0, 0, 0.4)',
    },
    '& .MuiCheckbox-root.Mui-checked': {
      color: '#f51d44',
    },
    '& .MuiButtonBase-root': {
        position: 'absolute',
        top: 0,
        left: 0,
    },
    '& .MuiTypography-root': {
        marginTop: 8,
    },
    '& .MuiTypography-body2': {
        position: 'absolute',
        bottom: mobile ? 0 : 8,
    },
}))

export const AnalysisPanel: React.FC<Props> = ({ onSubmit }) => {

    const {  isMobile, isTablet } = useContext(LayoutContext);
    const { limits } = useContext(UserContext);
    const { analysisType: defaultParameter } = useAnalysisType();

    const panels = useMemo(() => (
        defaultParameter.map((par) => par.name)
    ), [defaultParameter])


    const { register, handleSubmit, control, watch, formState: { isSubmitted } } = useForm();

    const file = watch('file');
    const morfologia = watch('Morfologia')
    const tarczyca = watch('Tarczyca')

    if (panels.length === 0) {
        return (
            <Loading />
        )
    }

    return (
        <Paper style={{ padding: 16}}>
            {!(morfologia || tarczyca) && isSubmitted &&
                <Alert variant="outlined" severity="error">
                    Proszę wybrać interesujacy Panel
                </Alert>
            }
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} display="flex" justifyContent="center" my={4}>
                        {file && file.length > 0 ?
                            <Button variant="outlined" disabled sx={{ backgroundColor: 'transparent' }}>
                                <img alt='addFile' src='/assets/addedFile.svg'/>
                                <Typography mx={3}> Twój plik z wynikami badań został dodany </Typography>
                            </Button>
                            :
                            <Button
                                size="large"
                                variant="contained"
                                component="label"
                            >
                                <img alt='ocr' src='/assets/ocrButton.svg'/>
                                <Typography mx={3}> Załącz plik z wynikami </Typography>
                                <input
                                    {...register('file')}
                                    type="file"
                                    hidden
                                />
                            </Button>
                        }
                    </Grid>
                    <ContainedAnalysisPanel container tablet={isTablet}>
                        {panels.map((analysis, index) => {
                            const findPackage = defaultParameter.find(parameter => parameter.name ===analysis)?.package_code
                            const limitsPanel = limits.find(limit => limit.package_code === findPackage)
                            const usedAllLimits = (limitsPanel?.used || 1) >= (limitsPanel?.limit || 0)
                            return (
                            <Grid item key={index} xs={12} sm={4} display="flex" justifyContent="center" mt={2}>
                                <StyledFormControlLabel
                                    mobile={isMobile}
                                    tablet={isTablet}
                                    disabled={usedAllLimits && limits.length > 0}
                                    index={index}
                                    value={analysis}
                                    control={
                                        <Controller
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <Fragment>
                                                    <img src={`/assets/${findPackage}.svg`}
                                                         height="50px"
                                                         width="100%"
                                                         alt='logo'/>
                                                    <Checkbox {...field} disabled={usedAllLimits && limits.length > 0}/>
                                                    {limits.length > 0 && <Typography variant="body2" component="span">
                                                        Wykorzystano {limitsPanel?.used} z {limitsPanel?.limit}
                                                    </Typography>}
                                                </Fragment>
                                                )
                                            }
                                            name={analysis}
                                            control={control}
                                        />
                                    }
                                    label={analysis}
                                />
                            </Grid>
                            )}
                        )}
                    </ContainedAnalysisPanel>
                    <Grid item xs={12} mt={4}>
                       <Stepper />
                    </Grid>
                </Grid>
            </form>
        </Paper>
    )
}