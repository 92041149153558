import React from 'react';
import ReactHtmlParser from 'html-react-parser';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AccordionSummaryProps, Box,
    Grid,
    ListItem,
    Paper,
    styled,
    Tooltip,
    Typography,
} from '@mui/material';
import { ArrowBackIos, Info } from '@mui/icons-material';
import { DiagnosisType, LabTestType } from 'clients/diagnosis/diagnosisClient.types';
import { Results } from 'views/LoggedIn/Results/Results';
import { MiddleLayerAnswers as MiddleLayerAnswersType } from 'clients/feedback/feedbackClient.types';
import { MiddleLayerAnswers } from 'views/LoggedIn/MiddleLayerAnswers/MiddleLayerAnswers';


const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary
        expandIcon={<ArrowBackIos sx={{ fontSize: '0.9rem', color: 'red' }} />}
        {...props}
    />
))(({ theme }) => ({
    fontSize: 21,
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(-90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
        fontWeight: 600,
        color: theme.palette.secondary.main,
        fontSize: 15,
    },
}));

type Props = {
    feedback?: DiagnosisType[],
    sex?: string,
    date_of_birth?: string;
    lab_tests?: LabTestType[];
    middle_layer_display?: MiddleLayerAnswersType
}

type DiagnosisProps = {
    diagnosis: Props;
}
export const Diagnosis: React.FC<DiagnosisProps> = ({ diagnosis}) => {

    const transform = (node: any) => {

        if (node.type === 'tag' && node.name === 'tooltip-desc') {
            let tooltipText
            if (node.children.length === 1) {
                tooltipText = node.firstChild.data;
            } else if ( node.children.length > 1 && node.type==='tag' ){
                node.children.filter((child: any) => child.type==='tag').forEach((child: any, index: number) => {
                    let test = child.children.filter((el: any) => el.name==='li').map((liElement: any) => liElement.firstChild.data.trim())
                    tooltipText = (
                        <ul key={index}>
                            {test.map((element: string, index: number) => (
                                <ListItem key={index} sx={{ fontWeight: 600, paddingTop: 0, paddingLeft: 0 }}>
                                    {element.trim()}
                                </ListItem>
                            ))}
                        </ul>
                    )
                    if (test.length === 0) {
                        tooltipText = (
                            <Box >
                                {node.children.map((element: any) => {
                                    if (element.data) {
                                        return <ListItem sx={{ listStyle: 'none' }}>{element.data}</ListItem>
                                    }
                                    return ''
                                })
                                }
                            </Box>
                        )
                    }
                })
            }
            return (
                <Typography component="span">
                    <sup>
                        <Tooltip title={tooltipText} enterDelay={800} enterTouchDelay={30} sx={{ cursor: "pointer" }}>
                            <Info style={{ height: 15 }} color="error"/>
                        </Tooltip>
                    </sup>
                </Typography>
            )
        }
        if (node.type === 'tag' && node.name === 'br') {
            if (node.next && node.next.type==='tag' && (node.next.name==='br' || node.next.name==='details')) {
                return <></>
            }
        }

    }

    return (
        <Paper sx={{ padding: 1 }}>
            <Grid item xs={12}>
                <Accordion>
                    <StyledAccordionSummary> Wyniki badania: </StyledAccordionSummary>
                    {diagnosis.sex && diagnosis.date_of_birth && diagnosis.lab_tests &&
                    <AccordionDetails>
                        <Results sex={diagnosis?.sex} date_of_birth={diagnosis?.date_of_birth} labTests={diagnosis?.lab_tests}/>
                    </AccordionDetails>
                    }
                </Accordion>
            </Grid>
            {diagnosis.middle_layer_display &&
                <Grid item xs={12}>
                    <Accordion>
                        <StyledAccordionSummary> Odpowiedzi na pytania dodatkowe: </StyledAccordionSummary>
                        <AccordionDetails>
                            <MiddleLayerAnswers answers={diagnosis.middle_layer_display.middle_layer_display_answers}/>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            }

            {diagnosis?.feedback && diagnosis.feedback.map((feedback) => (
                <Grid item xs={12} my={4}>
                    <Typography variant="h2" sx={theme => ({ color: theme.palette.error.main })}>
                        {feedback.analysis_type}
                    </Typography>
                    <Accordion>
                        <StyledAccordionSummary> Opis wyniku badania</StyledAccordionSummary>
                        <AccordionDetails>
                            {ReactHtmlParser(feedback.description, {
                                replace: transform,
                                trim: true
                            })}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <StyledAccordionSummary>W diagnostyce różnicowej uwzględnij</StyledAccordionSummary>
                        <AccordionDetails>
                            {ReactHtmlParser(feedback.diagnosis, {
                                replace: transform,
                                trim: true
                            })}

                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <StyledAccordionSummary>Sugerowane postępowanie</StyledAccordionSummary>
                        <AccordionDetails>
                            {ReactHtmlParser(feedback.recommendation, {
                                replace: transform,
                                trim: true
                            })}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <StyledAccordionSummary>Zbierając wywiad zapytaj o</StyledAccordionSummary>
                        <AccordionDetails>
                            {ReactHtmlParser(feedback.interview, {
                                replace: transform,
                                trim: true
                            })}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <StyledAccordionSummary>W badaniu fizykalnym</StyledAccordionSummary>
                        <AccordionDetails>
                            {ReactHtmlParser(feedback.physical, {
                                replace: transform,
                                trim: true
                            })}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <StyledAccordionSummary>Prawdopodobne scenariusze kliniczne</StyledAccordionSummary>
                        <AccordionDetails>
                            {ReactHtmlParser(feedback.scenarios, {
                                replace: transform,
                                trim: true
                            })}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion  disableGutters={true}>
                        <StyledAccordionSummary>Informacje dodatkowe</StyledAccordionSummary>
                        <AccordionDetails>
                            {ReactHtmlParser(feedback.reference.replace('<br>', ''), {
                                replace: transform,
                                trim: true
                            })}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            ))}
        </Paper>
    )
}

