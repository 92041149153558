export const routes = {
    login: '/login',
    signup: '/signup',
    resetPassword: '/reset-password',
    changePassword: '/change-password',
    interpretation: '/interpretation',
    interpretationHistory: '/interpretation-history',
    account: '/account',
    privacyPolicy: '/privacy-policy',
    rulesAndRegulations: '/rules-and-regulations',
    information: '/information',
    emailVerification: '/weryfikacja-email',
    passwordResetVerification: '/potwierdz-reset-hasla',
    reportMedicalIncidents: '/report-medical-incidents',
    interpretationHistoryDetail: (feedbackId: string | number = ':feedbackId') => `/interpretation-history/${feedbackId}`,
}

export const cacheKeys = {
    user: {
        getMe: 'getMe',
    },
    agreements: {
        getAgreements: 'getAgreements',
    },
    ocr: {
        postOCR: 'postOCR',
    },
    diagnosis: {
        createDiagnosis: 'createDiagnosis',
    },
    feedback: {
        getFeedbacks: 'getFeedbacks',
        getFeedback: 'getFeedback',
    },
    analysisType: {
        getAnalysisType: 'getAnalysisType',
    },
    middleLayer: {
        getMiddleLayer: 'getMiddleLayer',
        postMiddleLayer: 'postMiddleLayer',
    },
    parameters: {
        getParameters: 'getParameters',
    },
    getConfirmPasswordReset: 'getConfirmPasswordReset',
    getVerifyEmail: 'getVerifyEmail',

}


export const ALLOWED_DOMAINS = ["lazarski.edu.pl", "saventic.com", "kwkr.pl", "bloodlab.pl"]
export const tryBloodlab = 'try.bloodlab.pl'
