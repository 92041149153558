import React, { Fragment, useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Container } from '@mui/material';
import {routes, tryBloodlab} from 'config';
import { NavBar } from 'views/Public/Navbar';
import { Header } from 'views/Public/Header';
import { Authenticated, NotAuthenticated } from 'shared/components/auth/Authenticated';
import { Login } from 'views/LoggedOut/Login';
import { SignUp } from 'views/LoggedOut/SignUp';
import { ResetPassword } from 'views/LoggedOut/ResetPassword';
import { Interpretation } from 'views/LoggedIn/Interpretation/Interpretation';
import { Footer } from 'views/Public/Footer';
import { Account } from 'views/LoggedIn/Account/Account';
import { InterpretationHistories } from 'views/LoggedIn/InterpretationHistories/InterpretationHistoryList';
import { PrivacyPolicy } from './Public/Agreements/PrivacyPolicy/PrivacyPolicy';
import { RulesAndRegulations } from "./Public/Agreements/RulesAndRegulations/RulesAndRegulations";
import { Info } from 'shared/components/info/Info';
import { InterpretationHistory } from './LoggedIn/InterpretationHistory/InterpretationHistory';
import { EmailVerification } from './LoggedOut/EmailVerification';
import { UserContext } from 'contexts/UserContext/UserContext';
import {ChangePassword} from "./LoggedIn/ChangePassword/ChangePassword";
import {PasswordResetVerification} from "./LoggedIn/PasswordResetVerification/PasswordResetVerification";
import {ReportMedicalIncidents} from "./Public/Agreements/MedicalIncidents/ReportMedicalIncidents";


export const Root = () => {

    const { isLoggedIn, forceChangePassword } = useContext(UserContext);
    const isTryBloodlab = window.location.host === tryBloodlab

    if (isLoggedIn === undefined) {
        return ( <Fragment>Loading...</Fragment>
        )
    }

    if (forceChangePassword) {
        return (
            <Container>
                <Routes>
                    <Route path={routes.changePassword} element={<ChangePassword />}/>
                    <Route path="*" element={<Navigate to={routes.changePassword}/>} />
                </Routes>
            </Container>
        )
    }

    return (
        <Fragment>
            <NavBar />
            <Header />
            <Container>
                <NotAuthenticated>
                    <Routes>
                        <Route path={routes.login} element={<Login/>}/>
                        {!isTryBloodlab && <Route path={routes.signup} element={<SignUp/>}/>}
                        <Route path={routes.resetPassword} element={<ResetPassword/>}/>
                        <Route path={routes.privacyPolicy} element={<PrivacyPolicy/>}/>
                        <Route path={routes.rulesAndRegulations} element={<RulesAndRegulations/>}/>
                        <Route path={routes.information} element={<Info/>}/>
                        <Route path={routes.emailVerification} element={<EmailVerification/>}/>
                        <Route path={routes.passwordResetVerification} element={<PasswordResetVerification/>}/>
                        {isTryBloodlab && <Route path={routes.reportMedicalIncidents} element={<ReportMedicalIncidents/>}/>}
                        <Route path="*" element={<Navigate to={routes.login}/>} />
                    </Routes>
                </NotAuthenticated>
                <Authenticated>
                    <Routes>
                        <Route path={routes.interpretation} element={<Interpretation/>}/>
                        <Route path={routes.interpretationHistory} element={<InterpretationHistories/>}/>
                        <Route path={routes.interpretationHistoryDetail()} element={<InterpretationHistory/>}/>
                        <Route path={routes.account} element={<Account/>}/>
                        <Route path={routes.privacyPolicy} element={<PrivacyPolicy/>}/>
                        {isTryBloodlab && <Route path={routes.reportMedicalIncidents} element={<ReportMedicalIncidents/>}/>}
                        <Route path={routes.rulesAndRegulations} element={<RulesAndRegulations/>}/>
                        <Route path="*" element={<Navigate to={routes.interpretation}/>} />
                    </Routes>
                </Authenticated>
            </Container>
            <Footer/>
        </Fragment>
        )
}