import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { cacheKeys } from 'config';
import { feedbackClient } from 'clients/feedback/feedbackClient';

export type Params = {
    id: number;
};

export const useFeedback = (params: Params) => {

    const { data, status, error } = useQuery(
        [cacheKeys.feedback.getFeedback, params],
        () => feedbackClient.getFeedback(params),
    );

    return {
        status,
        error: error as AxiosError,
        feedback: data,
    };
};
