import { request} from 'clients/client';
import { PaginatedResults } from 'shared/helpers/Query';
import { Agreement} from './aggrementClient.types';
import { aggrementClientFormatter } from './aggrementClient.formatter';
import {env} from "../../env";

const baseApiUrl = env.REACT_APP_API_URL;

const getAgreements = () => {
    return request({
        authenticate: false,
        options: {
            url: `${baseApiUrl}agreements/`,
            method: 'GET',
        },
    }).then(data => data.data).then((data: PaginatedResults<Agreement>) => ({
        ...data,
        results: data.results.map(aggrementClientFormatter),
    }))
};

export const agreementsClient = {
    getAgreements,
};