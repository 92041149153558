import React from 'react';
import { Grid, Link, Typography, List, ListItem, styled } from '@mui/material';

const StyledList = styled(List)(() => ({
    marginLeft: 16,
}));
export const PrivacyPolicy = () => {

    return (
        <Grid container justifyContent="center" p={8}>
            <Typography fontWeight={700} width={400}>
                POLITYKA PRYWATNOŚCI PORTALU BLOODLAB DOSTĘPNEGO NA STRONIE EDU.BLOODLAB.PL
            </Typography>
            <Grid item  xs={12} mt={2}>
                <Typography fontWeight={700}>
                    I. Administrator danych osobowych
                </Typography>
                <StyledList>
                    <ListItem >
                        Administratorem Twoich danych osobowych przetwarzanych zgodnie z niniejszą Polityką Prywatności jest Saventic Health Sp. z o. o., ul. Władysława Łokietka 5, 87-100 Toruń, Polska, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego pod numerem: 0000758906 (dalej: „Administrator”, „Saventic” lub „My”).
                    </ListItem>
                    <ListItem>
                        W sprawach dotyczących Twoich danych osobowych możesz skontaktować się z Nami:
                        <StyledList>
                            <ListItem>
                                W sprawach dotyczących Twoich danych osobowych możesz skontaktować się z Nami:
                                pocztą tradycyjną lub osobiście pod adresem – ul. Władysława Łokietka 5, 87-100 Toruń, Polska,  lub
                            </ListItem>
                            <ListItem>
                                za pomocą wiadomości e-mail:
                                <Link component="a" href="mailto:info@bloodlab.pl" color="inherit" ml={1}>
                                    info@bloodlab.pl
                                </Link>.
                            </ListItem>
                        </StyledList>
                    </ListItem>
                </StyledList>
            </Grid>
            <Grid item  xs={12} mt={2}>
                <Typography fontWeight={700}>
                    II. Cel, podstawy prawne i zakres przetwarzania w związku z korzystaniem z Portalu Bloodlab
                </Typography>
                <StyledList>
                    <ListItem>
                        W przypadku gdy korzystasz z Portalu Bloodlab Twoje dane osobowe będziemy przetwarzać w następującym celu:
                        <StyledList>
                            <ListItem>
                                uzyskiwania przez Ciebie Interpretacji wyników badań laboratoryjnych na podstawie umowy zawartej przez Nas z Uczelnią, przy czym wprowadzone przez Ciebie dane, w tym dane dotyczące zdrowia będziemy przetwarzać na podstawie udzielonej przez Ciebie zgody na ich przetwarzanie (na podstawie art. 6 ust. 1 lit. b oraz art. 9 ust. 2 lit. a RODO);
                            </ListItem>
                            <ListItem>
                                analizy korzystania z Portalu Bloodlab, jak również poprawy jego funkcjonowania i bezpieczeństwa – w oparciu o nasz prawnie uzasadniony interes jako administratora danych osobowych (na podstawie art. 6 ust. 1 lit. f RODO);
                            </ListItem>
                            <ListItem>
                                archiwizacji danych i wykonywania kopii zapasowych w związku z nałożonym na nas obowiązkiem prawidłowego zabezpieczenia danych i w oparciu o nasz prawnie uzasadniony interes jako administratora danych osobowych (na podstawie art. 6 ust. 1 lit. c oraz art. 6 ust. 1 lit. f RODO);
                            </ListItem>
                            <ListItem>
                                zapewnienia wysokich standardów jakości i bezpieczeństwa wyrobów medycznych, w szczególności zgłaszania zdarzeń i incydentów medycznych związanych z wyrobami medycznymi (na podstawie art. 9 ust. 2 lit. i RODO).
                            </ListItem>
                        </StyledList>
                    </ListItem>
                    <ListItem>
                        W przypadku gdy korzystasz ze Portalu Bloodlab zapisujemy tylko takie dane, które nam podasz w związku z tym, że są nam potrzebne do założenia konta, tj. adres poczty elektronicznej oraz imię i nazwisko oraz dane niezbędne do wykonania Interpretacji wyników badań laboratoryjnych, tj. płeć, wiek (na podstawie dnia i miesiąca urodzenia), data wykonania badań laboratoryjnych, wyniki badań laboratoryjnych, inne dane o zdrowiu (w przypadku przekazywania danych rzeczywistych).
                    </ListItem>
                    <ListItem>
                        Możemy również zbierać pewne dane o Użytkownikach Portalu Bloodlab w celu optymalizacji jego funkcjonowania i poprawy w przyszłości. Dane takie obejmować mogą:
                        <StyledList>
                            <ListItem>
                                adres IP;
                            </ListItem>
                            <ListItem>
                                rodzaj i wersję urządzenia z jakiego korzystasz;
                            </ListItem>
                            <ListItem>
                                rodzaj i wersję przeglądarki z jakiej korzystasz;
                            </ListItem>
                            <ListItem>
                                sposób korzystania z Portalu Bloodlab.
                            </ListItem>
                        </StyledList>
                    </ListItem>
                    <ListItem>
                        W większości przypadków nie będziemy w stanie Cię zidentyfikować jako Użytkownika z pomocą danych, o których mowa w ust. 4 i dane te będą dla nas anonimowe. W sytuacji natomiast, gdy jesteśmy w stanie połączyć je z Tobą, co może nastąpić gdy posiadamy dodatkowe dane z innego źródła, stać się one mogą dla nas Twoimi danymi osobowymi.
                    </ListItem>
                </StyledList>
            </Grid>
            <Grid item mt={2}>
                <Typography fontWeight={700}>
                    IV. Cel, podstawy prawne i zakres przetwarzania w związku z kontaktem
                </Typography>
                W przypadku, gdy postanowisz się z nami skontaktować za pomocą  podanych w Portalu Bloodlab adresów poczty elektronicznej, czy też za pomocą poczty tradycyjnej, albo osobiście, przetwarzać będziemy takie dane osobowe, które zostaną nam przez Ciebie podane lub są niezbędne do odpowiedzi na Twoje wiadomości (w tym dane identyfikacyjne oraz kontaktowe). Dane te przetwarzamy z uwagi na fakt, że są one niezbędne do realizacji obowiązków wynikających ze wskazanych wyżej przepisów lub do realizacji prawnie uzasadnionych interesów Saventic, to jest w celu:
                <StyledList>
                    <ListItem>
                        odpowiedzi na przesłaną do nas wiadomość i dalszy kontakt z Tobą w oparciu o nasz prawnie uzasadniony interes jako administratora danych osobowych (na podstawie art. 6 ust. 1 lit. f RODO);
                    </ListItem>
                    <ListItem>
                        archiwizacji i wykonywanie kopii zapasowych danych w związku z nałożonym na nas jako na administratora danych obowiązkiem prawidłowego zabezpieczenia danych (na podstawie art. 6 ust. 1 lit. c RODO).
                    </ListItem>
                </StyledList>
            </Grid>
            <Grid item mt={2}>
                <Typography fontWeight={700}>
                    V. Okres przetwarzania danych
                </Typography>
                <StyledList>
                    <ListItem>
                        Twoje dane osobowe będą przetwarzane:
                        <StyledList>
                            <ListItem>
                                w celu realizacji na Twoją rzecz świadczeń z tytułu umowy o wzajemnej współpracy zawartej między Uczelnią, a Saventic – od dnia podania nam przez Ciebie danych do czasu zakończenia świadczenia Usług na Twoją rzecz, w tym usunięcia konta;
                            </ListItem>
                            <ListItem>
                                w celu kontaktu z Tobą – od dnia ich zebrania do czasu zakończenia prowadzenia korespondencji w sprawie, w której nawiązałeś kontakt lub upływu czasu, gdy racjonalnie możemy zakładać, że będziemy musieli się z Tobą skontaktować;
                            </ListItem>
                            <ListItem>
                                w celu dochodzenia i obrony przed roszczeniami – przez czas nie dłuższy niż okres przedawnienia roszczeń;
                            </ListItem>
                            <ListItem>
                                w celu poprawy funkcjonowania, bezpieczeństwa Portalu Bloodlab  – przez czas, przez jaki dane te są niezbędne do realizacji tego celu;
                            </ListItem>
                            <ListItem>
                                w celu archiwizacji i wykonywania kopii zapasowych – przez okres ustalony zgodnie z polityką wykonywania kopii zapasowych i archiwizacji w Saventic;
                            </ListItem>
                            <ListItem>
                                zapewnienia wysokich standardów jakości i bezpieczeństwa wyrobów medycznych, w szczególności zgłaszania zdarzeń i incydentów medycznych związanych z wyrobami medycznymi (na podstawie art. 9 ust. 2 lit. i RODO).
                            </ListItem>
                        </StyledList>
                    </ListItem>
                    <ListItem>
                        Każdorazowo jednak dane będziemy przetwarzać nie dłużej niż do chwili wyrażenia przez Ciebie skutecznego sprzeciwu wobec ich przetwarzania (gdy podstawą ich przetwarzania jest nasz prawnie uzasadniony interes) albo wycofania przez Ciebie zgody (jeżeli podstawą prawną przetwarzania danych jest Twoja zgoda).
                    </ListItem>
                </StyledList>
            </Grid>
            <Grid item mt={2}>
                <Typography fontWeight={700}>
                    VI. Odbiorcy danych
                </Typography>
                Dołożymy należytej staranności w doborze podmiotów, którym przekazywać będziemy Twoje dane i w przypadku takich wybranych podmiotów wymagać będziemy, by chronili Twoje dane za pomocą odpowiednich środków technicznych i organizacyjnych. Twoje dane osobowe mogą zostać ujawnione jedynie:
                <StyledList>
                    <ListItem>
                        osobom trzecim świadczącym na naszą rzecz usługi, które są potrzebne dla realizacji celów, w związku z którymi przetwarzamy Twoje dane (np. usług z zakresu IT, komunikacji elektronicznej, hostingu danych, usług z zakresu funkcjonowania Portalu Bloodlab dostępnego na stronie edu.bloodlab.pl);
                    </ListItem>
                    <ListItem>
                        odbiorcom, na rzecz których ujawnienie wymagane jest na mocy odpowiednich przepisów lub postanowienia sądu lub innego organu władzy;
                    </ListItem>
                    <ListItem>
                        innym odbiorcom, gdy przekazanie im danych jest niezbędne, by chronić Twoje żywotne interesy lub żywotne interesy innych osób fizycznych;
                    </ListItem>
                    <ListItem>
                        innym odbiorcom, jeżeli wyrazisz na nich swoją zgodę.
                    </ListItem>
                </StyledList>
            </Grid>
            <Grid item mt={2}>
                <Typography fontWeight={700}>
                    VII. Przekazywanie danych osobowych do państw trzecich
                </Typography>
                Każdorazowo, gdy Twoje dane osobowe będą przekazywane poza Europejski Obszar Gospodarczy (EOG) lub do państw, które nie zapewniają takiego samego lub odpowiedniego poziomu ochrony danych osobowych, zadbamy o to, by odbywało się to w oparciu o ważną podstawę prawną oraz z wykorzystaniem prawem wymaganych zabezpieczeń.
            </Grid>
            <Grid item mt={2}>
                <Typography fontWeight={700}>
                    VIII. Uprawnienia
                </Typography>
                W zakresie każdego z niżej wskazanych praw możesz skontaktować się z nami w szczególności za pomocą danych kontaktowych podanych w punkcie 1 Polityki Prywatności. Przysługuje Ci:
                <StyledList>
                    <ListItem>
                        <strong>Prawo do uzyskania informacji, dostępu do danych oraz do otrzymania kopii danych.</strong> Masz prawo w każdym czasie zażądać podania informacji o Twoich danych osobowych, które przechowujemy, lub do których mamy dostęp. Na Twoje żądanie zostanie Ci nieodpłatnie przedstawiona kopia Twoich danych osobowych podlegających przetwarzaniu. Za przesłanie każdej kolejnych kopii danych mamy prawo do zażądania opłaty, która pokryje uzasadnione koszty obsługi takiego żądania.
                    </ListItem>
                    <ListItem>
                        <strong>Prawo do wycofania zgody.</strong> Za każdym razem, gdy Twoje dane są przetwarzane w oparciu o udzieloną zgodę, masz prawo w każdym czasie tą zgodę wycofać, przy czym wycofanie zgody nie będzie miało wpływu na zgodność z prawem przetwarzania danych przed wycofaniem przez Ciebie zgody.
                    </ListItem>
                    <ListItem>
                        <strong>Prawo do sprostowania danych osobowych.</strong> Podejmujemy racjonalnie uzasadnione kroki w celu zapewnienia, że Twoje dane osobowe są prawidłowe, kompletne i aktualne. Jeżeli konieczne będą do wprowadzenia zmiany tych danych, prosimy, byś nas o tym zawiadomił.
                    </ListItem>
                    <ListItem>
                        <strong>Prawo do przenoszenia danych.</strong> Masz prawo żądać przeniesienia danych osobowych w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego, a także żądania przesłania danych innemu administratorowi, w sytuacji gdy podstawą przetwarzania Twoich danych osobowych jest wyrażona zgoda.
                    </ListItem>
                    <ListItem>
                        <strong>Prawo do usunięcia danych i do ograniczenia przetwarzania.</strong> We wskazanych w przepisach o ochronie danych osobowych przypadkach masz prawo, by żądać usunięcia Twoich danych osobowych. Prawo to nie jest jednakże absolutne – mogą wystąpić sytuacje, gdy będziemy nadal uprawnieni, by Twoje dane osobowe przetwarzać. Możesz również zwrócić się o ograniczenie dalszego przetwarzania Twoich danych.
                    </ListItem>
                    <ListItem>
                        <strong>Prawo do wniesienia sprzeciwu wobec przetwarzania.</strong> We wskazanych w przepisach przypadkach masz prawo, by sprzeciwić się dalszemu przetwarzaniu Twoich danych, w sytuacji gdy podstawą przetwarzania danych osobowych jest nasz prawnie uzasadniony interes.
                    </ListItem>
                    <ListItem>
                        <strong>Prawo do wniesienia skargi do organu nadzorczego.</strong> Masz prawo do wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych – co do zasady będzie nim Prezes Urzędu Ochrony Danych Osobowych.
                    </ListItem>
                </StyledList>
            </Grid>
            <Grid item mt={2}>
                <Typography fontWeight={700}>
                    IX. Polityka stosowania plików cookies i innych podobnych technologii
                </Typography>
                <StyledList>
                    <ListItem>
                        Strona wykorzystuje pliki cookies (niewielkie pliki tekstowe, które są obsługiwane przez stronę internetową i zapisywane na Twoim urządzeniu) i inne podobne technologie, w tym narzędzia pochodzące od podmiotów trzecich, tj. Google Inc. (Google Analytics, Google Ads), Meta Platforms Ireland Ltd. (Meta Pixel).
                    </ListItem>
                    <ListItem>
                        Strona internetowa używa wskazanych narzędzi w zakresie niezbędnym do jej działania dla różnych celów, w tym:
                        <StyledList>
                            <ListItem>
                                w zakresie niezbędnym do działania Serwisu internetowego, to jest do zapewnienia funkcjonowania Serwisu internetowego, dostosowania sposobu wyświetlania strony do urządzeń, oprogramowania i preferencji Użytkowników oraz wybranych przez nich ustawień;
                            </ListItem>
                            <ListItem>
                                do dostosowania sposobu wyświetlania strony do urządzeń, oprogramowania i preferencji Użytkowników oraz wybranych przez nich ustawień;
                            </ListItem>
                            <ListItem>
                                do monitorowania i analizy w jaki sposób Użytkownicy korzystają ze strony internetowej oraz możliwości poprawy jej bezpieczeństwa i funkcjonowania.
                            </ListItem>
                        </StyledList>
                    </ListItem>
                    <ListItem>
                        Wykorzystujemy następujące rodzaje plików cookies:
                        <StyledList>
                            <ListItem>
                                pliki cookies niezbędne do funkcjonowania Serwisu internetowego;
                            </ListItem>
                            <ListItem>
                                pliki cookies analityczne badające zachowanie Użytkownika na stronie internetowej;
                            </ListItem>
                            <ListItem>
                                funkcjonalne pliki cookies umożliwiające zapamiętanie ustawień wybranych przez Użytkownika i personalizację (np. pod względem wybranego języka lub akceptacji ustawień w zakresie plików cookies);
                            </ListItem>
                            <ListItem>
                                pliki cookies stron trzecich – w wykorzystywanych urządzeniach mogą być również zapisywane pliki cookies innych podmiotów wskazanych powyżej.
                            </ListItem>
                        </StyledList>
                    </ListItem>
                    <ListItem>
                        Pliki cookies i inne stosowane przez nas narzędzia nie są używane w celu przetwarzania lub przechowywania danych osobowych i z założenia nie służą do bezpośredniej identyfikacji Użytkownika. Nie zmieniają także ustawień przeglądarki Użytkownika, ani nie zmieniają konfiguracji urządzenia Użytkownika. Sytuacje wyjątkowe, gdy dane zebrane za pomocą plików cookies uznane mogą zostać za Twoje dane osobowe, a także związane z tym Twoje uprawnienia, szczegółowo opisane zostały we odpowiedniej części Polityki Prywatności.
                    </ListItem>
                </StyledList>
            </Grid>
            <Grid item mt={2}>
                <Typography fontWeight={700}>
                    X. Sposoby wyłączenia plików cookies
                </Typography>
                <StyledList>


                    <ListItem>
                        Wchodząc na naszą stronę internetową, masz możliwość wyrażenia zgody na wykorzystywanie plików cookies do celów innych niż niezbędne do działania strony internetowej. Nawet jeżeli już wyraziłeś zgodę, możesz skorzystać z opcji opisanych poniżej.
                    </ListItem>
                    <ListItem>
                        Każdy Użytkownik może wyłączyć pliki cookies w swojej przeglądarce internetowej, co może wpłynąć na sposób wyświetlania strony internetowej lub możliwość korzystania z niej. W celu ułatwienia zarządzania plikami cookies, poniżej przedstawiamy linki do niektórych stron przeglądarek:
                        <StyledList>
                            <ListItem>
                                Google Chrome:
                                <Link component="a" target="_blank" href="https://support.google.com/chrome/answer/95647?Every=GE NIE.Platform%3DDesktop&amp;hl=en" ml={1}>
                                    https://support.google.com/chrome/answer/95647?Every=GE NIE.Platform%3DDesktop&amp;hl=en
                                </Link>
                            </ListItem>
                            <ListItem>
                                Opera:
                                <Link component="a" target="_blank" href="https://help.opera.com/en/latest/web-preferences/#cookies" ml={1}>
                                    https://help.opera.com/en/latest/web-preferences/#cookies
                                </Link>
                            </ListItem>
                            <ListItem>
                                Firefox:
                                <Link component="a" target="_blank" href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" ml={1}>
                                    https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
                                </Link>
                            </ListItem>
                            <ListItem>
                                Apple Safari:
                                <Link component="a" target="_blank" href="https://support.apple.com/en-us/guide/safari/manage-cookies-and-website-data-sfri11471/mac" ml={1}>
                                    https://support.apple.com/en-us/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                                </Link>
                            </ListItem>
                            <ListItem>
                                Microsoft Egde:
                                <Link component="a" target="_blank" href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" ml={1}>
                                    https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09.
                                </Link>
                            </ListItem>
                        </StyledList>
                    </ListItem>
                    <ListItem>
                        Użytkownik może także tak ustawić przeglądarkę, by samodzielnie każdorazowo akceptować cookies. Wówczas przeglądarka za każdym razem przed udzieleniem dostępu dla pliku cookies będzie pytać Użytkownika o zgodę na udzielenie tego dostępu.
                    </ListItem>
                    <ListItem>
                        Masz możliwość wyłączenia przekazywania Twoich danych do celów analitycznych i statystycznych z wykorzystaniem Google Analytics. W tym celu możesz zainstalować rozszerzenie do przeglądarki internetowej zgodnie z instrukcją podaną pod tym adresem:
                        <Link component="a" target="_blank" href="https://support.google.com/analytics/answer/181881?hl=en" ml={1}>
                            https://support.google.com/analytics/answer/181881?hl=en.
                        </Link>

                    </ListItem>
                </StyledList>


            </Grid>

        </Grid>

    )
}