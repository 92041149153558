import React, { useContext } from 'react';
import {
    Grid,
    MenuItem,
    Select, styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { setMarker } from 'shared/helpers/form';
import { ArrowDownward, ArrowUpward, ErrorOutline } from '@mui/icons-material';
import { grey, red } from '@mui/material/colors';
import { Controller, useFieldArray } from 'react-hook-form';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { CustomParameter } from 'clients/diagnosis/diagnosisClient.types';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';



const StyledTextField = styled(TextField)(() => ({
    width: 100,
    'input': {
        padding: 5,
        textAlign: 'center',
    },
    '& .MuiInputBase-input.MuiInput-input.Mui-disabled': {
        opacity: 1,
        webkitTextFillColor: 'rgba(0, 0, 0, 0.1)'
    },
}));

export const ExaminationTable: React.FC<any> = ({
    panel,
    control,
    watch,
    errors,
    setError,
}) => {

    const { isMobile } = useContext(LayoutContext);
    const { formDict } = useContext(LocalizationContext);

    const isBiggerThenMax = (low: any, index: any) => {
        low = parseFloat(low)
        index = parseInt(index)
        if (!parametersWatch?.[index]?.high || !low) {
            return true
        }
        if (low > parametersWatch?.[index]?.high) {
            setError(`parameters.${index}.low`, { type: 'validate' });
            return 'Minimalna wartość nie może być wyższa niż maksymalna'
        }
    }

    const isSmallerThenMin = (high: any, index: any) => {
        high = parseFloat(high)
        index = parseInt(index)
        if (!parametersWatch?.[index]?.low || !high) {
            return true
        }
        if (high < parametersWatch?.[index]?.low) {
            setError(`parameters.${index}.high`, { type: 'validate' });
            return 'Maksymalna wartość nie może być niższa niż minimalna'
        }
    }

    const { fields } = useFieldArray<{ parameters: CustomParameter[]}>({
        control,
        name: "parameters",
    });
    const parametersWatch = watch("parameters");


    return (
        <Grid item xs={12}  my={1}>
            <Typography variant="h2" my={2}>{panel}</Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        {!isMobile && <TableRow>
                            <TableCell>Parametry:</TableCell>
                            <TableCell>Jednostka:</TableCell>
                            <TableCell>Wartość:</TableCell>
                            <TableCell>Minimalna wartość referencyjna:</TableCell>
                            <TableCell>Maksymalna wartość referencyjna:</TableCell>
                            <TableCell>Marker:</TableCell>
                        </TableRow>
                        }
                    </TableHead>
                    <TableBody>
                        {fields.filter((field) => field.analysisType === panel).map((parameter) => {
                            let index = parametersWatch.findIndex((par: any) => par.parameter === parameter.parameter);
                            let marker = setMarker(parametersWatch[index].value_numeric, parametersWatch[index].low, parametersWatch[index].high)
                            return (
                                <TableRow
                                    id={`parameters.${index}`}
                                    key={parameter.name}
                                    sx={{
                                        display: isMobile ? 'flex' : '',
                                        flexDirection: isMobile ? 'column' : '',
                                        marginTop: 5,
                                        borderTop: '1px solid #d2d8ff',
                                        '& td:nth-of-type(odd)': {
                                            backgroundColor: '#FAFAFF',
                                        },
                                    }}>
                                    <TableCell style={{
                                        color: '#01127e',
                                    }}>
                                        <Typography variant="h3">
                                            {parameter.parameter}
                                            <Typography component="span" color="red">
                                                {parameter.required && <sup>*</sup>}
                                            </Typography>
                                            <Tooltip title={parameter.name_synonym.join(', ')} sx={{ cursor: "pointer" }}>
                                                <ErrorOutline style={{ height: 15 }}/>
                                            </Tooltip>
                                        </Typography>

                                    </TableCell>
                                    <TableCell style={{
                                        backgroundColor: !!errors?.parameters?.[index]?.unit ? red['100'] : '',
                                        display: isMobile ? 'flex' : '',
                                        justifyContent: isMobile ? 'space-between' : 'center',
                                        alignItems: 'center',
                                        paddingLeft: 4,
                                        paddingRight: 4
                                    }}>
                                        {isMobile && <Typography variant="body2">Jednostka:</Typography>}
                                        <Controller
                                            name={`parameters.${index}.unit`}
                                            control={control}
                                            rules={{ required: parameter.required && formDict.requiredField}}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    sx={{ boxShadow: 'none',
                                                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                                                        '.MuiSelect-select': { padding: 1 },
                                                        maxWidth: 100,
                                                    }}
                                                    fullWidth
                                                >
                                                    {parameter.units.map((unit: string, index: number) => (
                                                        <MenuItem key={index} value={unit}>{unit}</MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell style={{
                                        backgroundColor: !!errors?.parameters?.[index]?.value_numeric ? red['100'] : '',
                                        display: isMobile ? 'flex' : '',
                                        justifyContent: isMobile ? 'space-between' : 'center',
                                        alignItems: 'center',
                                        paddingLeft: 4,
                                        paddingRight: 4
                                    }}>
                                        {isMobile && <Typography variant="body2">Wartość:</Typography>}
                                        <Controller
                                            name={`parameters.${index}.value_numeric`}
                                            control={control}
                                            rules={{ required: parameter.required && formDict.requiredField,
                                                validate: value =>  value !== undefined ? value > 0 : true
                                            }}
                                            render={({field}) => (
                                                <StyledTextField
                                                    {...field}
                                                    type='number'
                                                    variant="standard"
                                                    InputProps={{ disableUnderline: true }}
                                                    error={!!errors?.parameters?.[index]?.value_numeric}
                                                    helperText={errors?.parameters?.[index]?.value_numeric?.message}
                                                />
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell style={{
                                        backgroundColor: !!errors?.parameters?.[index]?.low ? red['100'] : '',
                                        display: isMobile ? 'flex' : '',
                                        justifyContent: isMobile ? 'space-between' : 'center',
                                        alignItems: 'center',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                    }}>
                                        {isMobile && <Typography variant="body2">Minimalna wartość referencyjna:</Typography>}
                                        <Controller
                                            name={`parameters.${index}.low`}
                                            control={control}
                                            rules={{
                                                required: parameter.required && parameter.is_ref_range_needed && formDict.requiredField,
                                                validate: (low: number) => isBiggerThenMax(low, index)
                                            }}
                                            render={({field}) => (
                                                <StyledTextField
                                                    {...field}
                                                    type='number'
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true
                                                    }}
                                                    error={!!errors?.parameters?.[index]?.low}
                                                    helperText={errors?.parameters?.[index]?.low?.message}
                                                />
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell style={{
                                        backgroundColor: !!errors?.parameters?.[index]?.high ? red['100'] : '',
                                        display: isMobile ? 'flex' : '',
                                        justifyContent: isMobile ? 'space-between' : 'center',
                                        alignItems: 'center',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                    }}>
                                        {isMobile && <Typography variant="body2">Maksymalna wartość referencyjna:</Typography>}
                                        <Controller
                                            name={`parameters.${index}.high`}
                                            control={control}
                                            defaultValue={parameter.high}
                                            rules={{
                                                required: parameter.required && parameter.is_ref_range_needed && formDict.requiredField,
                                                validate: (high) => isSmallerThenMin(high, index)
                                            }}
                                            render={({field}) => (
                                                <StyledTextField
                                                    {...field}
                                                    type='number'
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true
                                                    }}
                                                    error={!!errors?.parameters?.[index]?.high}
                                                    helperText={errors?.parameters?.[index]?.high?.message}
                                                />
                                            )}

                                        />
                                    </TableCell>
                                    <TableCell sx={{textAlign: "center",
                                        display: isMobile ? 'flex' : '',
                                        justifyContent: isMobile ? 'space-between' : 'center',
                                        alignItems: 'center',
                                        paddingLeft: '4px',
                                        paddingRight: '4px',
                                    }}>
                                        {isMobile && <Typography variant="body2">Marker:</Typography>}
                                        <Grid sx={{
                                            color: (marker === 'L' || marker === 'H') ? 'red' : grey['600'],
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            minWidth: 100,
                                        }}>
                                            {marker}
                                            {marker === 'L' && <ArrowDownward fontSize="small"/>}
                                            {marker === 'H' && <ArrowUpward fontSize="small"/>}
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}