import React, { Fragment, useCallback, useContext, useEffect } from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {Box} from '@mui/material';
import {AnalysisPanel} from 'views/LoggedIn/Interpretation/partials/AnalysisPanel';
import {diagnosisClient} from 'clients/diagnosis/diagnosisClient';
import {cacheKeys} from 'config';
import { OCRParameter } from 'clients/diagnosis/diagnosisClient.types';
import {LayoutContext} from 'contexts/LayoutContext/LayoutContext';
import {PatientInformation} from 'views/LoggedIn/Interpretation/partials/PatientInformation';
import {LocalizationContext} from 'contexts/LocalizationContext/LocalizationContext';
import {Diagnosis} from 'views/LoggedIn/Diagnosis/Diagnosis';
import {Stepper} from 'views/LoggedIn/Interpretation/partials/Stepper';
import {InterpretationHeader} from './partials/InterpretationHeader';
import {MiddleLayer} from "../MiddleLayer/MiddleLayer";
import {BasicPatientData} from "../BasicPatientData/BasicPatientData";
import {analysisTypeClient} from 'clients/analysisType/analysisType';


export const Interpretation = () => {
    const [OCRResults, setOCRResults] = React.useState<OCRParameter[]>([]);
    const [diagnosis, setDiagnosis] = React.useState({});
    const [defaultParameters, setDefaultParameters] = React.useState<OCRParameter[]>([]);
    const [sex, setSex] = React.useState('');
    const [birthDate, setBirthDate] = React.useState('');
    const [testDate, setTestDate] = React.useState('');
    const [middleLayer, setMiddleLayer] = React.useState('');
    const [chosenAnalysis, setChosenAnalysis] = React.useState<string[]>();
    const queryClient = useQueryClient();

    const {genericSnackbar} = useContext(LayoutContext);
    const {
        activeStep,
        backToFirstPage,
        handleNext,
        handleNextSkipQuestions,
        setLoading
    } = useContext(LocalizationContext);


    const createOCR = useMutation(diagnosisClient.postOCR, {
        mutationKey: [cacheKeys.ocr.postOCR],
        onSuccess: (results) => {
            if (results.ocr_status === "OCR_SUCCESS") {
                const parameters = results?.results[0]?.parameters
                setOCRResults(parameters);
            }
        },
        onError: () => {
            genericSnackbar('Cos poszlo nie tak', 'error');
        },
    });


    const submitPanel = useCallback(async (data: any) => {
        const {file, ...analysis} = data
        const chosenPanels = Object.keys(analysis).filter((panel) => analysis[panel] !== false)
        await setChosenAnalysis(chosenPanels)
        if (file && file.length > 0) {
            await createOCR.mutateAsync(file[0])
        }
        else {
            setOCRResults([])
        }
        if (!Object.values(analysis).includes(true)) {
            return ''
        }
        handleNext()

    }, [handleNext, createOCR]);


    const createFeedBack = useMutation(diagnosisClient.createDiagnosis, {
        mutationKey: [cacheKeys.diagnosis.createDiagnosis],
        onSuccess: (results) => {
            if (results.feedback.find(singleFeedback => singleFeedback.result_code === 'A102')) {
                handleNext();
                setMiddleLayer(results.middle_layer_link)
            } else {
                setDiagnosis(results);
                handleNextSkipQuestions();
                queryClient.invalidateQueries([cacheKeys.user.getMe])
            }

        },
        onError: () => {
            genericSnackbar('Cos poszlo nie tak', 'error');
        },
    });

    const submitAnalysis = useCallback(async (data: any) => {
        data = {sex: sex, date_of_birth: birthDate, test_date: testDate, ...data}
        await createFeedBack.mutateAsync(data);
    }, [createFeedBack, sex, birthDate, testDate]);

    const createMiddleLayer = useMutation(diagnosisClient.createMiddleLayer, {
        mutationKey: [cacheKeys.middleLayer.postMiddleLayer],
        onSuccess: (results) => {
            setDiagnosis(results);
            handleNext();
            queryClient.invalidateQueries([cacheKeys.user.getMe])
        },
        onError: () => {
            genericSnackbar('Cos poszlo nie tak', 'error');
        },
    });

    const submitMiddleLayer = useCallback(async (data: any) => {
        await createMiddleLayer.mutateAsync({guid: middleLayer, data});
    }, [createMiddleLayer, middleLayer]);

    const createBasicPatientInformation = useMutation(analysisTypeClient.getParameters, {
        mutationKey: [cacheKeys.parameters.getParameters],
        onSuccess: (results) => {
            setDefaultParameters(results);
            handleNext()
        },
        onError: () => {
            genericSnackbar('Cos poszlo nie tak', 'error');
        },
    });


    const submitBasicPatientInfo = useCallback(async (data: any) => {
        await createBasicPatientInformation.mutateAsync({
            sex: data.sex,
            chosenAnalysis: chosenAnalysis?.join(',') ?? '',
            date_of_birth: data.date_of_birth
        })
        setSex(data.sex)
        setBirthDate(data.date_of_birth)
        setTestDate(data.test_date)
    }, [chosenAnalysis, createBasicPatientInformation]);

    const steps = [
        {
            description: <AnalysisPanel onSubmit={submitPanel}/>
        },
        {
            description: <BasicPatientData onSubmit={submitBasicPatientInfo}/>
        },
        {
            description: <PatientInformation onSubmit={submitAnalysis} chosenAnalysis={chosenAnalysis}
                                             OCRResults={OCRResults} defaultParameters={defaultParameters}
                                             sex={sex} birthDate={birthDate}
            />
        },
        {
            description: <MiddleLayer link={middleLayer} onSubmit={submitMiddleLayer}/>
        },
        {
            description: (
                <Fragment>
                    <Diagnosis diagnosis={diagnosis}/> <Stepper/>
                </Fragment>
            )
        },
    ];


    useEffect(() => {
        if (createFeedBack.isLoading || createOCR.isLoading || createMiddleLayer.isLoading) {
            setLoading(true)
        } else {
            setLoading(false)
        }
        // DON'T ADD backToFirstPage to dependencies!!
    }, [createFeedBack, createOCR, createMiddleLayer, setLoading])

    useEffect(() => {
        backToFirstPage()
    }, [])

    return (
        <Box>
            <InterpretationHeader/>
            <Box mt={4}>
                {steps[activeStep].description}
            </Box>
        </Box>
    );
}