import {AxiosError, AxiosResponse} from 'axios';
import { request } from 'clients/client';
import {
    ChangePassword, CodeEmailParams,
    Login,
    RefreshToken,
    ResetPassword,
    SignUp,
    UpdateUser,
    User,
    UserApi
} from './userClient.types';
import { mapChangePasswordData, mapSignUpData, mapUpdateUserProfile, mapUserData } from './userClient.formatter';
import recaptchaVerify, { reCaptchaHeaders } from './reCaptcha';
import {env} from "../../env";
import { PaginatedResults } from 'shared/helpers/Query';


const usersApiBaseUrl = env.REACT_APP_API_URL;


const login = async (data: Login) => {
    const token = await recaptchaVerify("LOGIN")

    return request({
        authenticate: false,
        options: {
            url: `${usersApiBaseUrl}token/`,
            method: 'POST',
            data,
            headers: reCaptchaHeaders(token, "LOGIN")
        }
    });
}

const signup = async (data: SignUp, agreement: number[]): Promise<AxiosResponse<PaginatedResults<SignUp>, AxiosError>> => {
    const token = await recaptchaVerify("REGISTER")

    return request({
        authenticate: false,
        options: {
            url: `${usersApiBaseUrl}users/register/`,
            method: 'POST',
            data: {...mapSignUpData(data), agreements: agreement.length === 0 ? [] : agreement},
            headers: reCaptchaHeaders(token, "REGISTER")
        }
    });
}
const getMe = (): Promise<AxiosResponse<User>> => {
    return request({
        options: {
            url: `${usersApiBaseUrl}users/me/`,
            method: 'GET',
        },
    }).then((data: AxiosResponse<UserApi>) => ({
        ...data,
        data: mapUserData(data.data),
    }));
};

const updateUserProfile = (data: UpdateUser, id: string) => {

    return request({
        options: {
            url: `${usersApiBaseUrl}users/${id}/`,
            method: 'PATCH',
            data: {'user_profile': mapUpdateUserProfile(data)}
        },
    })
}

const resetPassword = async  (data: ResetPassword): Promise<AxiosResponse<User>> => {
    const token = await recaptchaVerify("PASSWORD_RESET")

    return request({
        options: {
            url: `${usersApiBaseUrl}/users/init_password_reset/`,
            method: 'POST',
            data: {email: data.email},
            headers: reCaptchaHeaders(token, "PASSWORD_RESET")
        },
    });
};

const changePassword = async  (data: ChangePassword): Promise<AxiosResponse<User>> => {

    return request({
        options: {
            url: `${usersApiBaseUrl}users/password_change/`,
            method: 'POST',
            data: mapChangePasswordData(data),
        },
    });
};

const verifyEmail = async  (params: CodeEmailParams) => {

    return request({
        options: {
            url: `${usersApiBaseUrl}users/verify_email/`,
            method: 'GET',
            params
        },
    })
};
const confirmPasswordReset = async  (params: CodeEmailParams) => {

    return request({
        options: {
            url: `${usersApiBaseUrl}users/confirm_password_reset/`,
            method: 'GET',
            params
        },
    })
};

const refreshToken = async (data: RefreshToken) => {

    return request({
        authenticate: false,
        options: {
            url: `${usersApiBaseUrl}token/refresh/`,
            method: 'POST',
            data,
        }
    });
}




export const userClient = {
    confirmPasswordReset,
    login,
    getMe,
    signup,
    resetPassword,
    changePassword,
    updateUserProfile,
    verifyEmail,
    refreshToken,
};