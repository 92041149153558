import React, { useCallback, useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Divider, Grid, Paper, Link, Typography } from '@mui/material';
import { UserContext } from 'contexts/UserContext/UserContext';
import {routes, tryBloodlab} from 'config';
import ReCaptchaComponent from 'shared/components/reCaptcha/ReCaptchaComponent';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { UserAuthForm } from './partials/AuthForm';

interface LoginType {
    email: string;
    password: string;
}

export const Login = () => {
    const [ error, setError ] = useState<boolean>(false)
    const { login: userLogin } = useContext(UserContext)
    const { isMobile } = useContext(LayoutContext);

    const onSubmit = useCallback( async(data: LoginType) => {
        try {
            await userLogin({...data})
        } catch(err) {
            setError(true)
        }
    }, [userLogin]);

    const isTryBloodlab = window.location.host === tryBloodlab

    return (
        <Grid container sx={{ justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
            <ReCaptchaComponent/>
            <Paper>
                <Box p={isMobile ? 2 : 10}>
                    <Grid item mb={3} xs={12} textAlign="center">
                        <img src='/assets/logo.svg' height="40px" width="100%" alt='logo'/>
                    </Grid>
                    <UserAuthForm
                        onSubmitRequest={onSubmit}
                        onError={error}
                        onSubmitButtonText="Zaloguj się"
                        hiddenFields={['firstName', 'lastName', 'repeatedEmail', 'repeatedPassword', 'oldPassword']}
                    />
                    <Grid container sx={{ justifyContent: isTryBloodlab ? 'end' : 'space-between' }}>
                        <Grid item xs={12}>
                            <Divider style={{ marginTop: 24, marginBottom: 24 }}/>
                        </Grid>
                        {!isTryBloodlab && <Grid item xs={12} sm={6} textAlign={isMobile ? "center" : 'start'}>
                            <Typography variant="body2">Nie masz jeszcze konta?</Typography>
                            <Link sx={{textDecoration: 'none'}} component={RouterLink} to={routes.signup}>
                                Zarejestruj się
                            </Link>
                        </Grid>}
                        <Grid item xs={12} sm={6} textAlign={isMobile ? "center" : 'end'}>
                            <Typography variant="body2">Nie pamiętasz hasła?</Typography>
                            <Link sx={{ textDecoration: 'none' }} component={RouterLink} to={routes.resetPassword}>Przypomnij hasło</Link>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Grid>
    )
}