import { ALLOWED_DOMAINS } from 'config';

export const validEmail = new RegExp(/^(([^<>()[\]\\.;:\s@]+(\.[^<>()[\]\\.`{},;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
export const validPassword = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+.,`{}=!*()@%&]).{8,}$/)

export const domainsEmailValidation = (email: string) => {
    for (let domain of ALLOWED_DOMAINS)
        if (email.includes(domain))
            return true
    return false
}

export const checkValidUUID = (uuid: any): boolean => {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return regexExp.test(uuid);
}
