export const genderMapper = {
    'f': 'Kobieta',
    'm': 'Mężczyzna',
    'unknown': 'Nie Podano',
}

export const calculateYears = (data: string) => {
    const today: Date = new Date()
    const birthday: Date = new Date(data)
    const diff = Math.abs(today.getFullYear() - birthday.getFullYear());
    return diff
}