import ReCaptchaV2 from './reCaptcha.types';
import {env} from "../../env";

declare const grecaptcha: ReCaptchaV2.ReCaptcha & {
    enterprise: ReCaptchaV2.ReCaptcha;
};

export const RE_CAPTCHA = env.REACT_APP_RE_CAPTCHA_KEY || ''


const recaptchaVerify = (action: string) => {
    return new Promise<string>((resolve, reject) => {
        grecaptcha.enterprise.ready(() => {
            grecaptcha.enterprise.execute(RE_CAPTCHA, {action: action})
                .then((token: string) => {
                        resolve(token);
                    },
                    (error: any) => {
                        reject(error)
                    });
        });
    })
}
export default recaptchaVerify

export const reCaptchaHeaders = (reToken: string, action: string) => {
    return {
        "g-recaptcha-response": reToken,
        "g-recaptcha-action": action
    }
}

