declare global {
    interface Window {
        env: any
    }
}

// change with your own variables
type EnvType = {
    REACT_APP_API_URL: string,
    REACT_APP_OCR_API_KEY: string,
    REACT_APP_OCR_API_URL: string,
    WDS_SOCKET_PORT: string,
    REACT_APP_PUBLIC_URL: string,
    REACT_APP_RE_CAPTCHA_KEY: string,
    REACT_APP_RE_CAPTCHA_SCRIPT: string,
    RE_CAPTCHA_ACTION_LOGIN: string,
}
export const env: EnvType = { ...process.env, ...window.env }