import React, {useContext} from 'react';
import { Box, Grid, Link, Typography, List, ListItem, Paper, styled } from '@mui/material';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';

const StyledListItem = styled(ListItem)((theme) => ({
    color: theme.theme.palette.secondary.main,
    fontSize: 13,
    paddingBottom: 8,
    lineHeight: '20px',
    fontFamily: 'Montserrat Thin',
    paddingLeft: 0,
}));

const StyledList = styled(List)(() => ({
    marginLeft: 16,
}));
export const ReportMedicalIncidents = () => {

    const { isMobile } = useContext(LayoutContext);

    return (
        <Grid container justifyContent="center" p={8} gap={3}>
            <Paper>
                <Box p={isMobile ? 2 : 10}>
                    <Typography fontWeight={700} sx={(theme) => ({
                        color: theme.palette.error.main
                    })}
                    >
                        Czym jest incydent medyczny?
                    </Typography>
                    <StyledListItem>
                        Incydent medyczny – wszelkie wadliwe działanie lub pogorszenie właściwości lub działania, w tym błąd użytkowy wynikający z cech ergonomicznych, wyrobu udostępnionego na rynku, a także wszelkie nieprawidłowości w informacjach podanych przez producenta oraz wszelkie działania niepożądane;
                    </StyledListItem>
                    <StyledListItem>
                        Poważny incydent - incydent, który bezpośrednio lub pośrednio doprowadził, mógł doprowadzić lub może doprowadzić do któregokolwiek z niżej wymienionych zdarzeń:
                    </StyledListItem>
                    <StyledList>
                        <StyledListItem >
                            zgon pacjenta, użytkownika lub innej osoby;
                        </StyledListItem>
                        <StyledListItem >
                            czasowe lub trwałe poważne pogorszenie stanu zdrowia pacjenta, użytkownika lub innej osoby;
                        </StyledListItem>
                        <StyledListItem >
                            poważne zagrożenie zdrowia publicznego.
                        </StyledListItem>
                    </StyledList>
                </Box>
            </Paper>
            <Paper>
                <Box p={isMobile ? 2 : 10}>
                    <Typography fontWeight={700} sx={(theme) => ({
                        color: theme.palette.error.main
                    })}
                    >
                        Jak zgłosić incydent medyczny?
                    </Typography>
                    <List sx = {{ listStyleType: 'none' }}>
                        <StyledListItem>
                            Incydenty medyczne po zastosowaniu wyrobu medycznego dla którego firma Saventic Health Sp. z o.o.
                            jest producentem można zgłaszać wypełniając
                            <Link color='#f51d44' component="a" target="_blank" href="https://bloodlab.pl/static/pdf/formularz_incydenty_medyczne.pdf" ml={1}>
                                załączony formularz
                            </Link>
                            , a następnie przesłanie go jedną z
                            poniższych sposobów:
                        </StyledListItem>
                    </List>
                    <StyledListItem>
                        pocztą elektroniczną:
                        <Link color='#f51d44' component="a" href="mailto:im_bloodlab@saventic.com" ml={1}>
                            im_bloodlab@saventic.com
                        </Link>
                    </StyledListItem>
                    <StyledListItem sx = {{ paddingBottom: 0 }}>
                        listem poleconym lub przesyłką kurierską na adres:
                    </StyledListItem>
                    <List sx = {{ listStyleType: 'none', paddingTop: 0, paddingLeft: '18px' }}>
                        <StyledListItem>
                            Saventic Health sp. z o.o.<br/>
                            ul. Władysława Łokietka 5<br/>
                            87-100 Toruń
                        </StyledListItem>
                    </List>
                    <List sx = {{ listStyleType: 'none' }}>
                        <StyledListItem>
                            Kopię zgłoszenia incydentu prosimy o przesłanie Prezesowi Urzędu Rejestracji Produktów Leczniczych Wyrobów Medycznych i Produktów Biobójczych, pocztą elektroniczną (adres: incydenty@urpl.gov.pl), faksem (nr 22 492 11 29), listem poleconym lub przesyłką kurierską (ul. Ząbkowska 41, 03-736 Warszawa).
                        </StyledListItem>
                    </List>
                    <List sx = {{ listStyleType: 'none' }}>
                        <StyledListItem>
                            Prosimy o podanie jak najbardziej szczegółowego opisu incydentu medycznego. Każda informacja może okazać się bardzo ważna w celu ustalenia przyczyny zaistnienia incydentu oraz związku z zastosowaniem wyrobu medycznego a incydentem medycznym.
                        </StyledListItem>
                    </List>
                </Box>
            </Paper>
            <Paper>
                <Box p={isMobile ? 2 : 10}>
                    <Typography fontWeight={700} sx={(theme) => ({
                        color: theme.palette.error.main
                    })}
                    >
                        Kto może zgłosić incydent medyczny?
                    </Typography>
                    <List sx = {{ listStyleType: 'none' }}>
                        <StyledListItem>
                            Zgodnie z ustawą z dnia 7 kwietnia 2022 r. o wyrobach medycznych Incydent medyczny może zgłosić Prezesowi Urzędu każdy, kto powziął informację o incydencie medycznym na terytorium Rzeczypospolitej Polskiej.
                        </StyledListItem>
                    </List>
                    <List sx = {{ listStyleType: 'none' }}>
                        <StyledListItem>
                            Zgłaszając incydent medyczny zbieramy informacje o wyrobie medycznym, dzięki czemu zwiększamy jego bezpieczeństwo stosowania.
                        </StyledListItem>
                    </List>
                    <List sx = {{ listStyleType: 'none' }}>
                        <StyledListItem>
                            Informujemy, że podane przez Państwa informacje są traktowane jako poufne. Państwa dane osobowe będą przetwarzane przez Saventic Health Sp. z o.o na podstawie Ustawy z dnia 10 maja 2018 r. o ochronie danych osobowych i art. 6 ust. 1 lit. c i art. 9 ust. 2 lit. i Rozporządzenia UE 2016/679 z 27 kwietnia 2016 r. wyłącznie do celów związanych z realizacją obowiązku monitorowania bezpieczeństwa wyrobów medycznych.
                        </StyledListItem>
                    </List>
                </Box>
            </Paper>
        </Grid>
    )
}